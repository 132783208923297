import {
    callApi as baseCallApi,
    ICommonAPIErrorResponse,
    Options as IApiRequestOptions,
} from "@/services/common/api";
import { getAccessToken } from "@/services/trade/account";

export const enum CPluginErrorCodes {
    // ok
    Ok = "Ok", // all OK
    OkNone = "OkNone", // all OK-no operation

    // errors
    Error = "Error", // general error
    // InvalidData = "InvalidData", // invalid data
    TechProblem = "TechProblem", // server technical problem
    OldVersion = "OldVersion", // old client terminal
    NoConnect = "NoConnect", // no connection
    NotEnoughRights = "NotEnoughRights", // no enough rights
    TooFrequent = "TooFrequent", // too frequently access to server
    Malfunction = "Malfunction", // malfunctioned operation
    GenerateKey = "GenerateKey", // need to send public key
    SecuritySession = "SecuritySession", // security session start

    OpenPosition = "OpenPosition", // need clarify

    // account status
    AccountDisabled = "AccountDisabled", // account blocked
    BadAccountInfo = "BadAccountInfo", // bad account info
    PublicKeyMissing = "PublicKeyMissing", // no key

    // trade
    TradeTimeout = "TradeTimeout", // trade transaction timeout expired
    TradeBadPrices = "TradeBadPrices", // order has wrong prices
    // TradeBadStops = "TradeBadStops", // wrong stops level
    TradeBadVolume = "TradeBadVolume", // wrong lot size
    // TradeMarketClosed = "TradeMarketClosed", // market closed
    TradeDisable = "TradeDisable", // trade disabled
    TradeDisableReadOnly = "TRADE_DISABLED_USER_READ_ONLY", // trade disabled
    TradeDisableCloseOnly = "TRADE_DISABLED_ASSET_CLOSE_ONLY", // trade disabled
    TradeDisableExpiredContract = "TRADE_DISABLED_EXPIRED_CONTRACT",
    TradeDisableLowLiquidity = "NO_QUOTES_LOW_LIQUIDITY",
    TradeDisabledUserReadOnly = "TRADE_DISABLED_USER_READ_ONLY",
    TradeDisabledAssetCloseOnly = "TRADE_DISABLED_ASSET_CLOSE_ONLY",

    TradePriceChanged = "TradePriceChanged", // price changed
    // TradeOffquotes = "TradeOffquotes", // no quotes
    TradeBrokerBusy = "TradeBrokerBusy", // broker is busy
    TradeRequote = "TradeRequote", // requote
    // TradeNoMoney = "TradeNoMoney", // no enough money for order execution
    TradeOrderLocked = "TradeOrderLocked", // order is proceed by dealer and cannot be changed
    TradeLongOnly = "TradeLongOnly", // allowed only BUY orders
    TradeTooManyReq = "TradeTooManyReq", // too many requests from one client

    // order status notification
    TradeAccepted = "TradeAccepted", // trade request accepted by server and placed in request queue
    TradeProcess = "TradeProcess", // trade request accepted by dealerd
    TradeUserCancel = "TradeUserCancel", // trade request canceled by client

    // additional return codes
    TradeModifyDenied = "TradeModifyDenied", // trade modification denied
    TradeContextBusy = "TradeContextBusy", // trade context is busy (used in client terminal)
    TradeExpirationDenied = "TradeExpirationDenied", // using expiration date denied
    TradeTooManyOrders = "TradeTooManyOrders", // too many orders
    TradeHedgeProhibited = "TradeHedgeProhibited", // hedge is prohibited
    TradeProhibitedByFIFO = "TradeProhibitedByFIFO", // prohibited by fifo rule

    // Added by CPlugin
    UnhandledException = "UnhandledException",

    // actual codes that server receives
    TradeNoMoney = "INSUFFICIENT_FUNDS",
    TradeMarketClosed = "MARKET_CLOSED",
    InvalidParams = "INVALID_PARAMETERS",
    InvalidParamsWrongExpireDate = "INVALID_PARAMETERS_WRONG_EXPIRE_DATE",
    TradeBadStops = "INVALID_SL_TP",
    TradeOffquotes = "QUOTE_EXCEEDED",
    GeneralError = "GENERAL_ERROR",
    RequestPlaced = "REQUEST_PLACED",
}

export const callApi = async <T = unknown>(config: IApiRequestOptions): Promise<T | any> => {
    const { needAuth = true } = config;
    const authData = needAuth ? { authToken: getAccessToken() } : {};

    const urlParams = new URLSearchParams(window.location.search);
    const platformMode = urlParams.get("platformMode");

    config.queryParams = {
        ...config.queryParams,
        platformMode,
    };


    const response = await baseCallApi({
        ...config,
        ...authData,
    });

    const { isFailed, status } = response as ICommonAPIErrorResponse;
    const isAuthFailed = needAuth && isFailed && status === 401;

    if (!isAuthFailed) {
        return response as T;
    }

    return await baseCallApi({
        ...config,
    });
};
