import React, { FC, useEffect } from 'react';

export enum AudioType {
    OK = 'ok',
    ERROR = "error",
}

interface IAudioPlayer {
    type: AudioType;
    playing?: boolean;
    setPlaying?: (value: boolean) => void;
}


const AudioPlayer: FC<IAudioPlayer> = ({ type, playing, setPlaying }) => {

    useEffect(() => {
        if (!playing) {
            play();
        }
    }, []);

    const play = () => {
        const audio = new Audio(`/sound/${type}.mp3`);

        setPlaying && setPlaying(true);
        audio.play();
    };

    return (
        <></>
    );
};

export default React.memo(AudioPlayer);
