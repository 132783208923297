import { getUserTimezoneOffsetInHours } from "@/utils/dateTime";

interface IParentFrameAPI {
    signup: () => void;
    login: () => void;
    deposit: () => void;
    runDownloadPDF: (message: { dateFrom: string; dateTo: string }) => void;
}

interface IParentMessageFormat {
    action: string;
    source: string;
}

const ParentFrameAPI = (): IParentFrameAPI => {
    enum ParentFrameMessages {
        LOGIN = "login",
        DEPOSIT = "deposit",
        SIGNUP = "signUp",
    }

    type PostMessage = string | IParentMessageFormat | Record<string, unknown>;

    const postMessage = (message: PostMessage): void => {
        try {
            parent?.postMessage(message, "*");
        } catch (e) {
            console.log("Parent frame message error", e);
        }
    };

    const createActionMessage = (action: string): IParentMessageFormat => {
        return {
            action,
            source: "cfd-2", // TODO: to clarify
        };
    };

    const createTriggerDownloadPdfMessage = params => ({
        targetFunc: "downloadTradeHistory",
        data: {
            dateFrom: params.dateFrom,
            dateTo: params.dateTo,
            tz: `${getUserTimezoneOffsetInHours()}`,
        },
    });

    return {
        login: () => {
            console.log("LOGIN");
            postMessage(createActionMessage(ParentFrameMessages.LOGIN));
        },
        signup: () => postMessage(createActionMessage(ParentFrameMessages.SIGNUP)),
        deposit: () => postMessage(createActionMessage(ParentFrameMessages.DEPOSIT)),
        runDownloadPDF: params => postMessage(createTriggerDownloadPdfMessage(params)),
    };
};

const parentFrameAPI = ParentFrameAPI();

export default parentFrameAPI;
