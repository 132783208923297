import { useCallback, useMemo } from "react";

import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { convertAmountToCurrency, getRequiredMargin, getSymbolQuoteCurrency } from "@/utils/trade";
import { getLeverageDisplayValue, getSpreadInPoints, getSymbolAssets } from "@/utils/symbols";
import { useAccountDynamicInfo, useAccountInfo } from "@/redux/selectors/accountSelector";
import { useQuoteData } from "@/hooks/trade/tradeSocket";
import { formatAmount, formatNumber } from "@/utils/format";
import { ISymbolInfo } from "@/services/trade/symbols";
import { ITradeOrderOperationCategory } from "@/contexts/TradeOrder/interfaces";
import { ITradeInfoState } from "@/redux/interfaces/ITradeInfo";
import { TradeOrderOperationType } from "@/services/trade/order";
import { useTranslation } from "next-i18next";

// helpers
export const isForexSymbol = (symbol: ISymbolInfo): boolean =>
    symbol?.ex.profitCalculationMode === "Forex";

export const getTradeLotDisplayValue = (
    lotSize: number | string = 0.01,
    symbol: ISymbolInfo
): string => {
    const isForex = isForexSymbol(symbol);
    const roundedLotValue = Number(lotSize) * symbol?.contractSize;

    return `${formatNumber(roundedLotValue)} ${isForex ? symbol?.currency : "Units"}`;
};

interface ISymbolDynamicFields {
    leverage: number;
    leverageDisplayValue: string;
    isForex: boolean;
    isAccountCurrencySymbol: boolean;
    image1: string;
    image2: string;
    lotValue: number;
    lotDisplayValue: string;
    getLotDisplayValue: (value: string) => string;
    lotBuyValueInAccountCurrency: number;
    lotBuyDisplayValueInAccountCurrency: string;
    lotSellValueInAccountCurrency: number;
    lotSellDisplayValueInAccountCurrency: string;
    marginDisplayValueInSymbolCurrency: string;
    requiredBuyMargin: number;
    requiredBuyMarginDisplayValue: string;
    requiredSellMargin: number;
    requiredSellMarginDisplayValue: string;
    swapBuy: number;
    swapBuyDisplayValue: string;
    swapSell: number;
    swapSellDisplayValue: string;
    pointValue: number;
    pointDisplayValue: string;
    pipValue: number;
    pipDisplayValue: string;
    spreadInPoints: number;
    spreadInPointsDisplayValue: string;
    spreadInPips: number;
    spreadInPipsDisplayValue: string;
}

interface IActiveSymbolDynamicFieldsProps {
    lotSize?: number | string;
    symbolId?: string;
    operationType?: ITradeOrderOperationCategory;
}

interface IGetOrderRequiredMarginProps {
    lotSize: number | string;
    operationType: TradeOrderOperationType;
    symbolId: string;
    symbols: ITradeInfoState["symbols"];
    symbolsInfoById: ITradeInfoState["symbolsInfoById"];
    accountCurrency: string;
    accountLeverage: number;
    ask: number;
    bid: number;
}

export const getOrderRequiredMargin = (props: IGetOrderRequiredMarginProps): number => {
    const {
        lotSize,
        operationType,
        symbolId,
        symbols,
        symbolsInfoById,
        accountCurrency,
        accountLeverage,
        ask,
        bid,
    } = props;
    const symbolInfo = symbolsInfoById[symbolId];
    const leverage = symbolInfo?.ex?.leverage;

    return convertAmountToCurrency({
        amount: getRequiredMargin(
            accountCurrency,
            symbolInfo?.id,
            lotSize,
            symbolInfo?.contractSize,
            operationType === "Buy" ? ask : bid,
            symbolInfo?.ex?.profitCalculationMode,
            symbolInfo?.ex?.marginCalculationMode,
            accountLeverage,
            leverage,
            symbolInfo?.percentage
        ),
        fromCurrency: symbolInfo?.currency,
        toCurrency: accountCurrency,
        symbols,
        conversionPriceType: operationType === "Buy" ? "Ask" : "Bid",
    });
};

export const useActiveSymbolDynamicFields = (
    props: IActiveSymbolDynamicFieldsProps = {}
): ISymbolDynamicFields => {
    const { t } = useTranslation("common");
    const { symbols, activeSymbolId, symbolsInfoById } = useTradeInfo();
    let { symbolId, lotSize = 0.01 } = props;

    symbolId = symbolId || activeSymbolId;
    const symbolInfo = symbolsInfoById[symbolId];

    const { operationType = "Buy" } = props;

    const lotMin = symbolInfo?.group?.lotMin || 0.01;
    const lotMax = symbolInfo?.group?.lotMax || 100;
    lotSize = +lotSize > lotMax ? lotMin : lotSize;

    const { currency: accountCurrency } = useAccountInfo();
    const { leverage: accountLeverage } = useAccountDynamicInfo();
    const { ask: activeSymbolAsk, bid: activeSymbolBid } = useQuoteData(symbolId);

    const [image1, image2] = getSymbolAssets(symbolId, symbolInfo?.group?.name);

    const leverage = symbolInfo?.ex?.leverage;

    const leverageDisplayValue = `1:${getLeverageDisplayValue(symbolInfo, accountLeverage)}`;

    const isForex = isForexSymbol(symbolInfo);

    const isAccountCurrencySymbol = accountCurrency === symbolInfo?.currency;

    let lotValue = Number(lotSize) * symbolInfo?.contractSize;

    if (!isForex) {
        lotValue = lotValue * (operationType === "Sell" ? activeSymbolBid : activeSymbolAsk);
    }

    const lotBuyValueInAccountCurrency = convertAmountToCurrency({
        amount: lotValue,
        fromCurrency: symbolInfo?.currency,
        toCurrency: accountCurrency,
        symbols,
        conversionPriceType: "Ask",
    });

    const lotSellValueInAccountCurrency = convertAmountToCurrency({
        amount: lotValue,
        fromCurrency: symbolInfo?.currency,
        toCurrency: accountCurrency,
        symbols,
        conversionPriceType: "Bid",
    });

    // required margins
    const marginDisplayValueInSymbolCurrency = formatAmount(
        getRequiredMargin(
            accountCurrency,
            symbolInfo?.id,
            lotSize,
            symbolInfo?.contractSize,
            activeSymbolBid,
            symbolInfo?.ex?.profitCalculationMode,
            symbolInfo?.ex?.marginCalculationMode,
            accountLeverage,
            leverage,
            symbolInfo?.percentage
        ),
        symbolInfo?.currency,
        2
    );

    let requiredBuyMargin = getRequiredMargin(
        accountCurrency,
        symbolInfo?.id,
        lotSize,
        symbolInfo?.contractSize,
        activeSymbolAsk,
        symbolInfo?.ex?.profitCalculationMode,
        symbolInfo?.ex?.marginCalculationMode,
        accountLeverage,
        leverage,
        symbolInfo?.percentage
    );
    //console.log("requiredBuyMargin ===== BEFORE", requiredBuyMargin);
    //console.log("accountCurrency", accountCurrency);
    requiredBuyMargin = convertAmountToCurrency({
        amount: requiredBuyMargin,
        fromCurrency: symbolInfo?.currency,
        toCurrency: accountCurrency,
        symbols,
        conversionPriceType: "Ask",
    });
    // console.log("requiredBuyMargin ===== AFTER", requiredBuyMargin);
    let requiredSellMargin = getRequiredMargin(
        accountCurrency,
        symbolInfo?.id,
        lotSize,
        symbolInfo?.contractSize,
        activeSymbolBid,
        symbolInfo?.ex?.profitCalculationMode,
        symbolInfo?.ex?.marginCalculationMode,
        accountLeverage,
        leverage,
        symbolInfo?.percentage
    );

    requiredSellMargin = convertAmountToCurrency({
        amount: requiredSellMargin,
        fromCurrency: symbolInfo?.currency,
        toCurrency: accountCurrency,
        symbols,
        conversionPriceType: "Bid",
    });

    const requiredBuyMarginDisplayValue = formatAmount(requiredBuyMargin, accountCurrency);

    const requiredSellMarginDisplayValue = formatAmount(requiredSellMargin, accountCurrency);

    // swap buy
    const swapBuy = Number(lotSize) * symbolInfo?.ex?.swapBuy;
    const swapBuyConvertedAmount = isAccountCurrencySymbol
        ? null
        : convertAmountToCurrency({
              amount: swapBuy,
              fromCurrency: symbolInfo?.currency,
              toCurrency: accountCurrency,
              symbols,
          });
    const swapBuyConvertedDisplayValue =
        isAccountCurrencySymbol || Number.isNaN(swapBuyConvertedAmount)
            ? ""
            : ` (${formatAmount(swapBuyConvertedAmount, accountCurrency)})`;

    let maxSwapBuyDigits = 4;

    try {
        const swapBuyParts = `${swapBuy}`.split(".");
        const buyDecimalPart = swapBuyParts.length > 1 ? swapBuyParts[1] : "";
        maxSwapBuyDigits = buyDecimalPart.length > 4 ? 4 : buyDecimalPart.length;

    } catch (error) {
        maxSwapBuyDigits = 2;
    }

    const swapBuyDisplayValue = `${formatAmount(
        swapBuy,
        symbolInfo?.currency,
        maxSwapBuyDigits
    )}${swapBuyConvertedDisplayValue}`;

    // swap sell
    const swapSell = Number(lotSize) * symbolInfo?.ex?.swapSell;


    let maxSwapSellDigits = 4;
    try {
        const swapSellParts = `${swapSell}`.split(".");
        const sellDecimalPart = swapSellParts.length > 1 ? swapSellParts[1] : "";
        maxSwapSellDigits = sellDecimalPart.length > 4 ? 4 : sellDecimalPart.length;
    } catch (error) {
        maxSwapSellDigits = 2;
    }


    const swapSellConvertedAmount = isAccountCurrencySymbol
        ? null
        : convertAmountToCurrency({
              amount: swapSell,
              fromCurrency: symbolInfo?.currency,
              toCurrency: accountCurrency,
              symbols,
              conversionPriceType: "Bid",
          });
    const swapSellConvertedDisplayValue =
        isAccountCurrencySymbol || Number.isNaN(swapSellConvertedAmount)
            ? ""
            : ` (${formatAmount(swapSellConvertedAmount, accountCurrency)})`;
    const swapSellDisplayValue = `${formatAmount(
        swapSell,
        symbolInfo?.currency,
        maxSwapSellDigits
    )}${swapSellConvertedDisplayValue}`;

    const lotDisplayValue = useMemo(() => getTradeLotDisplayValue(lotSize, symbolInfo), [
        lotSize,
        symbolInfo,
    ]);

    const getLotDisplayValue = useCallback(value => getTradeLotDisplayValue(value, symbolInfo), [
        symbolInfo,
    ]);

    const lotBuyDisplayValueInAccountCurrency = useMemo(
        () =>
            lotBuyValueInAccountCurrency
                ? formatAmount(lotBuyValueInAccountCurrency, accountCurrency)
                : null,
        [lotBuyValueInAccountCurrency, accountCurrency]
    );

    const lotSellDisplayValueInAccountCurrency = useMemo(
        () =>
            lotSellValueInAccountCurrency
                ? formatAmount(lotSellValueInAccountCurrency, accountCurrency)
                : null,
        [lotSellValueInAccountCurrency, accountCurrency]
    );

    const pointValue = useMemo(() => {
        return convertAmountToCurrency({
            amount:
                (symbolInfo?.point * lotValue) /
                (isForex ? 1 : operationType === "Sell" ? activeSymbolBid : activeSymbolAsk), // Point value in Base currency
            fromCurrency: getSymbolQuoteCurrency(symbolInfo),
            toCurrency: accountCurrency,
            symbols,
            conversionPriceType: "Bid", // TODO: ask why Bid
        });
    }, [
        isForex,
        operationType,
        activeSymbolBid,
        activeSymbolAsk,
        lotValue,
        accountCurrency,
        symbolInfo,
        symbols,
    ]);

    const pointDisplayValue = useMemo(() => formatAmount(pointValue, accountCurrency, 3), [
        pointValue,
        accountCurrency,
    ]);

    const pipValue = 10 * pointValue;

    const pipDisplayValue = useMemo(() => formatAmount(pipValue, accountCurrency, 2), [
        pipValue,
        accountCurrency,
    ]);

    const spreadInPoints = getSpreadInPoints(activeSymbolAsk, activeSymbolBid, symbolInfo?.digits);

    const spreadInPointsDisplayValue = `${spreadInPoints} ${t("points")}`;

    const spreadInPips = spreadInPoints / 10;

    const spreadInPipsDisplayValue = isNaN(spreadInPips)
        ? `0 ${t("pips")}`
        : `${spreadInPips.toFixed(1)} ${t("pips")}`;

    return {
        leverage,
        leverageDisplayValue,
        isAccountCurrencySymbol,
        isForex,
        image1,
        image2,
        lotValue,
        lotDisplayValue,
        getLotDisplayValue,
        lotBuyValueInAccountCurrency,
        lotBuyDisplayValueInAccountCurrency,
        lotSellValueInAccountCurrency,
        lotSellDisplayValueInAccountCurrency,
        marginDisplayValueInSymbolCurrency,
        requiredBuyMargin,
        requiredBuyMarginDisplayValue,
        requiredSellMargin,
        requiredSellMarginDisplayValue,
        swapBuy,
        swapBuyDisplayValue,
        swapSell,
        swapSellDisplayValue,
        pointValue,
        pointDisplayValue,
        pipValue,
        pipDisplayValue,
        spreadInPoints,
        spreadInPointsDisplayValue,
        spreadInPips,
        spreadInPipsDisplayValue,
    };
};
