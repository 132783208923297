import { callApi } from "@/services/index";
import { removeEmpty } from "@/utils/index";
import { ICommonAPIErrorResponse } from "@/services/common/api";

export interface ILoginRequest {
    brand: string;
    cid?: string;
    currency?: string;
    flag?: string; // "esma" | "amf" | "cysec" | "hcmc";
    token?: string;
}

export interface ILoginResponse {
    accessToken: string;
}

export const login = async (
    params: ILoginRequest
): Promise<ILoginResponse | ICommonAPIErrorResponse> => {
    return await callApi({
        endpoint: "/api/auth",
        method: "POST",
        // @ts-ignore
        body: removeEmpty(params),
    });
};

export const setAccessToken = (token: string): void => window.sessionStorage.setItem("token", token);

export const getAccessToken = (): string => window.sessionStorage.getItem("token");
