import { call, put, select, takeLatest, delay } from "redux-saga/effects";
import { processedQuedOrdersString } from "@/utils/helpers";
import {
    closeOrder,
    deleteOrder,
    ICloseOrderQueryParams,
    IModifyOrderQueryParams,
    ITradeOrder,
    modifyOrder,
} from "@/services/trade/order";
import {
    ICloseTradeRequestAction,
    ICloseTradeSubmitAction,
    IDeleteOrderConfirmAction,
    IDeleteOrderRequestAction,
    IModifyOrderRequestAction,
    IModifyOrderSubmitAction,
    OrdersActionTypes,
} from "@/redux/reducers/ordersReducer";
import {
    closeTradeOrderModal,
    openTradeOrderModal,
    setAnimationParams,
    setOrderModifyPopupOpen,
    setOrderModifyPopupRequestPending,
} from "@/redux/actions/ui";
import { ITradeOrderModalTypes } from "@/redux/interfaces/IUI";
import { setActiveOrder } from "@/redux/actions/tradeInfo";
import {
    setGeneralError,
    setSuccessfullyClosedOrder,
    setSuccessfullyDeletedOrder,
    setSuccessfullyModifiedOrder,
} from "@/redux/actions/systemFeedback";
import { ICommonAPIErrorResponse } from "@/services/common/api";
const {
    DELETE_ORDER_REQUEST,
    DELETE_ORDER_CONFIRM,
    MODIFY_ORDER_REQUEST,
    MODIFY_ORDER_SUBMIT,
    MODIFY_ORDER_CANCEL,
    CLOSE_TRADE_REQUEST,
    CLOSE_TRADE_SUBMIT,
} = OrdersActionTypes;
import { getModalType } from "@/components/Home/TradingOrderSection/Modal";
import { closeDeleteOrderAction, modifyOrderAction, removeClosingTicket } from "../actions/orders";
import { IStore } from "../interfaces/IStore";
import { DevicePlatform } from "../interfaces/IDevice";
import { AnimationClasses } from "@/hooks/common/useAnimation";

function* delayEffect(ms) {
    yield call(delay, ms);
}

function* handleOrderDeleteRequest(action: IDeleteOrderRequestAction) {
    const { order } = action;

    yield put(
        openTradeOrderModal(ITradeOrderModalTypes.ORDER_DELETE_CONFIRM, {
            order,
        })
    );
}

function* handleOrderDeleteConfirm(action: IDeleteOrderConfirmAction) {
    const { order, args } = action;

    try {
        const result = yield call(deleteOrder, { ticket: order.ticket, args });
        yield put(closeTradeOrderModal());

        if ((result as ICommonAPIErrorResponse)?.isFailed) {
            const { message, code } = result as ICommonAPIErrorResponse;

            // TODO - temporary solution to avoid not found error when orders has already removed
            // need remove after socket events start working
            if (message?.toLowerCase() === "trade not found") {
                const pendingOrders = (yield select(
                    (state: IStore) => state.orders.pendingOrders
                )) as ITradeOrder[];
                const targetOrder = pendingOrders.find(order => action.order.ticket === order.ticket);

                if (targetOrder) {
                    yield put(closeDeleteOrderAction(targetOrder));
                }
                return;
            }

            const modalType = getModalType(code, message);
            console.log("modalType", modalType);

            yield put(
                openTradeOrderModal(modalType, {
                    error: message,
                })
            );
            // For system feedback on mobile view
            yield put(setGeneralError(message));
        } else {
            const platform = yield select((state: IStore) => state.device.platform);
            const isTradesTabActive = yield select((state: IStore) => state.ui.isTradesTabActive);

            const isPendingAnimation = platform !== DevicePlatform.Desktop && isTradesTabActive;

            if (isPendingAnimation) {
                yield put(setAnimationParams({ class: AnimationClasses.backOutRight, params: order }));
            } else {
                yield put(closeDeleteOrderAction(order as ITradeOrder));
            }

            yield put(openTradeOrderModal(ITradeOrderModalTypes.ORDER_DELETE_SUCCESS, { order }));

            // For system feedback on mobile view
            yield put(setSuccessfullyDeletedOrder(order as ITradeOrder));
        }
    } catch (error) {
        yield put(
            openTradeOrderModal(ITradeOrderModalTypes.ORDER_ERROR, {
                error: error,
            })
        );
        // For system feedback on mobile view
        yield put(setGeneralError(error));
    }
}

function* handleOrderModifyRequest(action: IModifyOrderRequestAction) {
    const { order } = action;

    yield put(setActiveOrder(order.ticket));
    yield put(setOrderModifyPopupOpen(true));
}

function* handleOrderModifySubmit(action: IModifyOrderSubmitAction) {
    const { request } = action;

    yield put(setOrderModifyPopupRequestPending(true));

    try {
        const result = yield call(modifyOrder, request as IModifyOrderQueryParams);

        yield put(setOrderModifyPopupOpen(false));
        yield put(setOrderModifyPopupRequestPending(false));
        yield put(setActiveOrder(null));

        const { ticket } = result as ITradeOrder;

        if (ticket) {
            yield put(modifyOrderAction(result));
            yield put(
                openTradeOrderModal(ITradeOrderModalTypes.ORDER_MODIFIED_SUCCESS, {
                    order: result as ITradeOrder,
                })
            );

            // For system feedback on mobile view
            yield put(setSuccessfullyModifiedOrder(result as ITradeOrder));
        } else {
            // error
            const { code: errorCode, message } = result as ICommonAPIErrorResponse;

            yield put(
                openTradeOrderModal(getModalType(errorCode, message), {
                    error: message,
                })
            );
            // For system feedback on mobile view
            yield put(setGeneralError(message));
        }
    } catch (error) {
        yield put(
            openTradeOrderModal(ITradeOrderModalTypes.ORDER_ERROR, {
                error: error,
            })
        );
    }
}

function* handleOrderModifyCancel() {
    yield put(setActiveOrder(null));
    yield put(setOrderModifyPopupOpen(false));
}

function* handleCloseTradeRequest(action: ICloseTradeRequestAction) {
    const { order } = action;

    yield put(
        openTradeOrderModal(ITradeOrderModalTypes.CLOSE_TRADE, {
            order,
        })
    );
}

function* handleCloseTradeSubmit(action: ICloseTradeSubmitAction) {
    const { request } = action;

    try {
        const result = yield call(closeOrder, request as ICloseOrderQueryParams);

        yield put(
            closeTradeOrderModal({
                order: { ...result, ticket: request.ticket } as ITradeOrder,
            })
        );

        if ((result as ICommonAPIErrorResponse)?.isFailed) {
            const { message, code } = result as ICommonAPIErrorResponse;

            // TODO - temporary solution to avoid "not found" error when orders has already removed
            // need remove after socket events start working
            if (message?.toLowerCase() === "trade not found") {
                const openOrders = (yield select(
                    (state: IStore) => state.orders.openTrades
                )) as ITradeOrder[];
                const targetOrder = openOrders.find(order => order.ticket === request.ticket);

                if (targetOrder) {
                    yield put(closeDeleteOrderAction(targetOrder));
                }
                return;
            }

            const modalType = getModalType(code, message);
            // REQUEST_PLACED
            if (modalType === ITradeOrderModalTypes.REQUEST_PLACED) {
                yield delay(10000);
                const time = processedQuedOrdersString.getLast();
                if (time) {
                    yield put(
                        openTradeOrderModal(modalType, {
                            error: message,
                            ticket: request?.ticket,
                        })
                    );
                    yield put(removeClosingTicket(request?.ticket));
                }
            } else {
                yield put(
                    openTradeOrderModal(modalType, {
                        error: message,
                        ticket: request?.ticket,
                    })
                );
                yield put(removeClosingTicket(request?.ticket));
            }

            // For system feedback on mobile view
            yield put(setGeneralError(message));
        } else {
            yield put(removeClosingTicket(request?.ticket));
            const platform = yield select((state: IStore) => state.device.platform);
            const isTradesTabActive = yield select((state: IStore) => state.ui.isTradesTabActive);

            const isPendingAnimation = platform !== DevicePlatform.Desktop && isTradesTabActive;

            if (isPendingAnimation) {
                yield put(setAnimationParams({ class: AnimationClasses.backOutRight, params: result }));
            } else {
                yield put(closeDeleteOrderAction(result));
            }

            yield put(
                openTradeOrderModal(ITradeOrderModalTypes.CLOSE_TRADE_SUCCESS, {
                    order: result as ITradeOrder,
                })
            );
            // For system feedback on mobile view
            yield put(setSuccessfullyClosedOrder(result as ITradeOrder));
        }
    } catch (error) {
        yield put(
            openTradeOrderModal(ITradeOrderModalTypes.ORDER_ERROR, {
                error: error,
            })
        );
        // For system feedback on mobile view
        yield put(setGeneralError(error));
    }
}

export function* ordersSaga() {
    // delete
    yield takeLatest(DELETE_ORDER_REQUEST, handleOrderDeleteRequest);
    yield takeLatest(DELETE_ORDER_CONFIRM, handleOrderDeleteConfirm);

    // modify
    yield takeLatest(MODIFY_ORDER_REQUEST, handleOrderModifyRequest);
    yield takeLatest(MODIFY_ORDER_SUBMIT, handleOrderModifySubmit);
    yield takeLatest(MODIFY_ORDER_CANCEL, handleOrderModifyCancel);

    // close trade
    yield takeLatest(CLOSE_TRADE_REQUEST, handleCloseTradeRequest);
    yield takeLatest(CLOSE_TRADE_SUBMIT, handleCloseTradeSubmit);
}
