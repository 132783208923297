import { ISystemFeedbackState, IFavoritesAlert } from "../interfaces/ISystemFeedback";
import { ITradeOrder } from "@/services/trade/order";

const initialState: ISystemFeedbackState = {
    successfullyClosedOrder: null,
    successfullyModifiedOrder: null,
    successfullyDeletedOrder: null,
    successfullyOpenedOrder: null,
    isShowOpenPositionWarning: false,
    generalErrorMessage: "",
    isShowSecureConnection: false,
    isShowMarginCall: false,
    isShowNoQuotes: false,
    isShowNoQuotes_lowLiquidity: false,
    isShowOrderFailed: false,
    isShowOrderHasWrongPrice: false,
    favoritesAlerts: [],
};

export enum SystemFeedbackActionTypes {
    SET_SUCCESSFULLY_CLOSED_ORDER = "systemFeedback/SET_SUCCESSFULLY_CLOSED_ORDER",
    SET_SUCCESSFULLY_MODIFIED_ORDER = "systemFeedback/SET_SUCCESSFULLY_MODIFIED_ORDER",
    SET_SUCCESSFULLY_DELETED_ORDER = "systemFeedback/SET_SUCCESSFULLY_DELETED_ORDER",
    SET_SUCCESSFULLY_OPENED_ORDER = "systemFeedback/SET_SUCCESSFULLY_OPENED_ORDER",
    SET_SHOW_OPEN_POSITION_WARNING = "systemFeedback/SET_SHOW_OPEN_POSITION_WARNING",
    SET_GENERAL_ERROR = "systemFeedback/SET_GENERAL_ERROR",
    SET_SHOW_SECURE_CONNECTION = "systemFeedback/SET_SHOW_SECURE_CONNECTION",
    SET_SHOW_MARGIN_CALL = "systemFeedback/SET_SHOW_MARGIN_CALL",
    SET_SHOW_NO_QUOTES = "systemFeedback/SET_SHOW_NO_QUOTES",
    SET_SHOW_NO_QUOTES_LOW_LIQUIDITY = "systemFeedback/SET_SHOW_NO_QUOTES_LOW_LIQUIDITY",
    SET_SHOW_ORDER_HAS_WRONG_PRICE = "systemFeedback/SET_SHOW_ORDER_HAS_WRONG_PRICE",
    SET_SHOW_ORDER_FAILED = "systemFeedback/SET_SHOW_ORDER_FAILED",
    SET_FAVORITES_ALERT = "systemFeedback/SET_FAVORITES_ALERT",
    RESET_FEEDBACK_MODALS = "RESET_FEEDBACK_MODALS",
}

export interface ISetNoQoutesAction {
    type: typeof SystemFeedbackActionTypes.SET_SHOW_NO_QUOTES;
    isShow: boolean;
}
export interface ISetNoQoutesLowLiquidityAction {
    type: typeof SystemFeedbackActionTypes.SET_SHOW_NO_QUOTES_LOW_LIQUIDITY;
    isShow: boolean;
}

export interface ISetOrderHasWrongPriceAction {
    type: typeof SystemFeedbackActionTypes.SET_SHOW_ORDER_HAS_WRONG_PRICE;
    isShow: boolean;
}

export interface ISetOrderFailedAction {
    type: typeof SystemFeedbackActionTypes.SET_SHOW_ORDER_FAILED;
    isShow: boolean;
}

export interface ISetSuccessfullyClosedOrderAction {
    type: typeof SystemFeedbackActionTypes.SET_SUCCESSFULLY_CLOSED_ORDER;
    order: ITradeOrder;
}

export interface ISetSuccessfullyModifiedOrderAction {
    type: typeof SystemFeedbackActionTypes.SET_SUCCESSFULLY_MODIFIED_ORDER;
    order: ITradeOrder;
}

export interface ISetSuccessfullyDeletedOrderAction {
    type: typeof SystemFeedbackActionTypes.SET_SUCCESSFULLY_DELETED_ORDER;
    order: ITradeOrder;
}

export interface ISetSuccessfullyOpenedOrderAction {
    type: typeof SystemFeedbackActionTypes.SET_SUCCESSFULLY_OPENED_ORDER;
    order: ITradeOrder;
}

export interface ISetFavoritesAlertAction {
    type: typeof SystemFeedbackActionTypes.SET_FAVORITES_ALERT;
    value: IFavoritesAlert;
}

export interface ISetShowOpenPositionWarningAction {
    type: typeof SystemFeedbackActionTypes.SET_SHOW_OPEN_POSITION_WARNING;
    isShow: boolean;
}

export interface ISetGeneralErrorAction {
    type: typeof SystemFeedbackActionTypes.SET_GENERAL_ERROR;
    errorMessage: string;
}

export interface ISetShowSecureConnectionAction {
    type: typeof SystemFeedbackActionTypes.SET_SHOW_SECURE_CONNECTION;
    isShow: boolean;
}

export interface ISetShowMarginCallAction {
    type: typeof SystemFeedbackActionTypes.SET_SHOW_MARGIN_CALL;
    isShow: boolean;
}

export interface IResetFeedbackModalsAction {
    type: typeof SystemFeedbackActionTypes.RESET_FEEDBACK_MODALS;
}

export type SystemFeedbackActions =
    | ISetSuccessfullyClosedOrderAction
    | ISetSuccessfullyModifiedOrderAction
    | ISetSuccessfullyDeletedOrderAction
    | ISetSuccessfullyOpenedOrderAction
    | ISetShowOpenPositionWarningAction
    | ISetGeneralErrorAction
    | ISetShowSecureConnectionAction
    | ISetShowMarginCallAction
    | ISetNoQoutesAction
    | ISetNoQoutesLowLiquidityAction
    | ISetOrderFailedAction
    | ISetOrderHasWrongPriceAction
    | IResetFeedbackModalsAction
    | ISetFavoritesAlertAction;

export const systemFeedbackReducer = (
    state = initialState,
    action: SystemFeedbackActions
): ISystemFeedbackState => {
    const { type } = action;

    switch (type) {
        case SystemFeedbackActionTypes.SET_SUCCESSFULLY_CLOSED_ORDER: {
            const { order } = action as ISetSuccessfullyClosedOrderAction;

            return {
                ...state,
                successfullyClosedOrder: order,
            };
        }

        case SystemFeedbackActionTypes.SET_SUCCESSFULLY_MODIFIED_ORDER: {
            const { order } = action as ISetSuccessfullyModifiedOrderAction;

            return {
                ...state,
                successfullyModifiedOrder: order,
            };
        }

        case SystemFeedbackActionTypes.SET_SUCCESSFULLY_DELETED_ORDER: {
            const { order } = action as ISetSuccessfullyDeletedOrderAction;

            return {
                ...state,
                successfullyDeletedOrder: order,
            };
        }

        case SystemFeedbackActionTypes.SET_SUCCESSFULLY_OPENED_ORDER: {
            const { order } = action as ISetSuccessfullyOpenedOrderAction;

            return {
                ...state,
                successfullyOpenedOrder: order,
            };
        }

        case SystemFeedbackActionTypes.SET_SHOW_OPEN_POSITION_WARNING: {
            const { isShow } = action as ISetShowOpenPositionWarningAction;

            return {
                ...state,
                isShowOpenPositionWarning: isShow,
            };
        }

        case SystemFeedbackActionTypes.SET_GENERAL_ERROR: {
            const { errorMessage } = action as ISetGeneralErrorAction;

            return {
                ...state,
                generalErrorMessage: errorMessage,
            };
        }

        case SystemFeedbackActionTypes.SET_SHOW_SECURE_CONNECTION: {
            const { isShow } = action as ISetShowSecureConnectionAction;

            return {
                ...state,
                isShowSecureConnection: isShow,
            };
        }

        case SystemFeedbackActionTypes.SET_SHOW_MARGIN_CALL: {
            const { isShow } = action as ISetShowMarginCallAction;

            return {
                ...state,
                isShowMarginCall: isShow,
            };
        }

        case SystemFeedbackActionTypes.SET_SHOW_NO_QUOTES: {
            const { isShow } = action as ISetNoQoutesAction;

            return {
                ...state,
                isShowNoQuotes: isShow,
            };
        }
        case SystemFeedbackActionTypes.SET_SHOW_NO_QUOTES_LOW_LIQUIDITY: {
            const { isShow } = action as ISetNoQoutesLowLiquidityAction;

            return {
                ...state,
                isShowNoQuotes_lowLiquidity: isShow,
            };
        }

        case SystemFeedbackActionTypes.SET_SHOW_ORDER_FAILED: {
            const { isShow } = action as ISetOrderFailedAction;

            return {
                ...state,
                isShowOrderFailed: isShow,
            };
        }

        case SystemFeedbackActionTypes.SET_SHOW_ORDER_HAS_WRONG_PRICE: {
            const { isShow } = action as ISetOrderHasWrongPriceAction;

            return {
                ...state,
                isShowOrderHasWrongPrice: isShow,
            };
        }

        case SystemFeedbackActionTypes.SET_FAVORITES_ALERT: {
            const { value } = action as ISetFavoritesAlertAction;

            if (value === null) {
                return {
                    ...state,
                    favoritesAlerts: [],
                };
            }

            if (value === undefined) {
                return {
                    ...state,
                    favoritesAlerts: state.favoritesAlerts.slice(1, state.favoritesAlerts.length),
                };
            }

            return {
                ...state,
                favoritesAlerts: [...state.favoritesAlerts, value],
            };
        }
        case SystemFeedbackActionTypes.RESET_FEEDBACK_MODALS: {
            return initialState;
        }
        default:
            return state;
    }
};
