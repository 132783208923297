import serverTime from "@/services/serverTime";
import moment from "moment";

/**
 * We receive date strings from trading in ISO format as UTC time, but in fact they contain
 * a timezone shift (Cyprus time). For example, "2022-09-05T16:41:04Z" while the correct
 * UTC time string would be "2022-09-05T13:41:04Z".
 * We need to compensate for that using the server timezone info
 * @param tradingPlatformDateString
 */
export const parsetradingPlatformDateString = (
    tradingPlatformDateString: string,
    withServerTime: boolean
): Date => {
    const date = new Date(tradingPlatformDateString); // parse it as UTC

    date.setHours(date.getHours() + (withServerTime ? serverTime.getTimezoneInfo().timezoneShift : 0));

    return date;
};

/**
 * For example, should return 3 for Kyiv
 */
export const getUserTimezoneOffsetInHours = (): number => (new Date().getTimezoneOffset() * -1) / 60;

export const createTimestamp = (): number => new Date().getTime();

export const formatDate = (date: unknown, format: string): string => moment(date).format(format);
