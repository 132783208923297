import groupBy from "lodash/groupBy";
import { processedQuedOrdersString } from "@/utils/helpers";
import { ICloseOrderQueryParams, IModifyOrderQueryParams, ITradeOrder } from "@/services/trade/order";

import { IOrdersState } from "../interfaces/IOrders";
import { isPendingOrder } from "@/utils/trade";
import { getDevicePlatformFromScreenWidth } from "@/utils/hooks/Device";
import { DevicePlatform } from "../interfaces/IDevice";

const initialState: IOrdersState = {
    openTrades: [],
    pendingOrders: [],
    closingTickets: [],
    volume: null,
};

export enum OrdersActionTypes {
    SET_TRADE_ALERTS = "orders/SET_TRADE_ALERTS",

    SET_ORDERS = "orders/SET_ORDERS",
    SET_ORDER = "orders/SET_ORDER",
    MODIFY_ORDER = "MODIFY_ORDER",
    CLOSE_DELETE_ORDER = "orders/CLOSE_DELETE_ORDER",
    ORDER_CHANGED_SOCKET = "orders/ORDER_CHANGED_SOCKET",

    DELETE_ORDER_REQUEST = "orders/DELETE_ORDER_REQUEST",
    DELETE_ORDER_CONFIRM = "orders/DELETE_ORDER_CONFIRM",
    MODIFY_ORDER_REQUEST = "orders/MODIFY_ORDER_REQUEST",
    MODIFY_ORDER_SUBMIT = "orders/MODIFY_ORDER_SUBMIT",
    MODIFY_ORDER_CANCEL = "orders/MODIFY_ORDER_CANCEL",
    CLOSE_TRADE_REQUEST = "orders/CLOSE_TRADE_REQUEST",
    CLOSE_TRADE_SUBMIT = "orders/CLOSE_TRADE_SUBMIT",
    REMOVE_CLOSING_TICKET = "orders/REMOVE_CLOSING_TICKET",
}

export type OrderOperationTypes = "Add" | "Update" | "Delete";

export interface IOrderSocketResponse {
    order: ITradeOrder;
    type: OrderOperationTypes;
}

export interface IOrderChangeFromSocketAction {
    type: typeof OrdersActionTypes.ORDER_CHANGED_SOCKET;
    payload: IOrderSocketResponse;
}

export interface ISetOrdersAction {
    type: typeof OrdersActionTypes.SET_ORDERS;
    orders: ITradeOrder[];
}

export interface ISetOrderAction {
    type: typeof OrdersActionTypes.SET_ORDER;
    order: ITradeOrder;
}

export interface IModifyOrderAction {
    type: typeof OrdersActionTypes.MODIFY_ORDER;
    order: Partial<ITradeOrder>;
}

export interface ICloseDeleteOrderAction {
    type: typeof OrdersActionTypes.CLOSE_DELETE_ORDER;
    order: ITradeOrder;
}

export interface IDeleteOrderRequestAction {
    type: typeof OrdersActionTypes.DELETE_ORDER_REQUEST;
    order: Partial<ITradeOrder>;
}

export interface IDeleteOrderConfirmAction {
    type: typeof OrdersActionTypes.DELETE_ORDER_CONFIRM;
    order: Partial<ITradeOrder>;
    args: {
        [key: string]: number;
    };
}

export interface IModifyOrderRequestAction {
    type: typeof OrdersActionTypes.MODIFY_ORDER_REQUEST;
    order: Partial<ITradeOrder>;
}

export interface IModifyOrderSubmitAction {
    type: typeof OrdersActionTypes.MODIFY_ORDER_SUBMIT;
    request: Partial<IModifyOrderQueryParams>;
}

export interface IModifyOrderCancelAction {
    type: typeof OrdersActionTypes.MODIFY_ORDER_CANCEL;
}

export interface ICloseTradeRequestAction {
    type: typeof OrdersActionTypes.CLOSE_TRADE_REQUEST;
    order: Partial<ITradeOrder>;
}

export interface ICloseTradeSubmitAction {
    type: typeof OrdersActionTypes.CLOSE_TRADE_SUBMIT;
    request: Partial<ICloseOrderQueryParams>;
}

export interface IRemoveClosingTicketAction {
    type: typeof OrdersActionTypes.REMOVE_CLOSING_TICKET;
    ticket: string | number;
}

export type OrdersActions =
    | ISetOrdersAction
    | ISetOrderAction
    | ICloseDeleteOrderAction
    | IModifyOrderAction
    | IDeleteOrderRequestAction
    | IDeleteOrderConfirmAction
    | IModifyOrderRequestAction
    | IModifyOrderSubmitAction
    | IModifyOrderCancelAction
    | ICloseTradeRequestAction
    | IOrderChangeFromSocketAction
    | ICloseTradeSubmitAction
    | IRemoveClosingTicketAction;

export const ordersReducer = (state = initialState, action: OrdersActions): IOrdersState => {
    const { type } = action;

    switch (type) {
        case OrdersActionTypes.CLOSE_TRADE_REQUEST: {
            return {
                ...state,
            };
        }

        case OrdersActionTypes.CLOSE_TRADE_SUBMIT: {
            const { request } = action as ICloseTradeSubmitAction;
            processedQuedOrdersString.put();

            return {
                ...state,
                closingTickets: [...state.closingTickets, request?.ticket],
                volume: request?.volume || null,
            };
        }

        case OrdersActionTypes.REMOVE_CLOSING_TICKET: {
            const { ticket } = action as IRemoveClosingTicketAction;

            return {
                ...state,
                closingTickets: state.closingTickets.filter(t => t !== ticket),
            };
        }

        case OrdersActionTypes.SET_ORDERS: {
            const { orders } = action as ISetOrdersAction;

            const groupedOrders: Partial<IOrdersState> = groupBy(
                [...orders].reverse(),
                (order: ITradeOrder) => (isPendingOrder(order) ? "pendingOrders" : "openTrades")
            );

            return {
                ...state,
                pendingOrders: groupedOrders?.pendingOrders || [],
                openTrades: groupedOrders?.openTrades || [],
            };
        }

        case OrdersActionTypes.SET_ORDER: {
            const { order } = action as ISetOrderAction;

            const orderType = isPendingOrder(order) ? "pendingOrders" : "openTrades";

            const isOrderExists = state[orderType].some(item => item.ticket === order.ticket);

            if (!isOrderExists) {
                return {
                    ...state,
                    [orderType]: [order, ...state[orderType]],
                };
            }
            return state;
        }

        case OrdersActionTypes.CLOSE_DELETE_ORDER: {
            const { order } = action as ICloseDeleteOrderAction;

            const orderType = isPendingOrder(order) ? "pendingOrders" : "openTrades";

            return {
                ...state,
                [orderType]: state[orderType].filter(({ ticket }) => ticket !== order.ticket),
            };
        }

        case OrdersActionTypes.MODIFY_ORDER: {
            const { order } = action as IModifyOrderAction;

            const orderType = isPendingOrder(order as ITradeOrder) ? "pendingOrders" : "openTrades";

            return {
                ...state,
                [orderType]: state[orderType].map(item => {
                    if (item.ticket === order.ticket) {
                        return order;
                    }
                    return item;
                }),
            };
        }

        case OrdersActionTypes.ORDER_CHANGED_SOCKET: {
            const { payload } = action as IOrderChangeFromSocketAction;
            const { order, type } = payload;

            const orderType = isPendingOrder(order) ? "pendingOrders" : "openTrades";

            if (type === "Add") {
                const isOrderExists = state[orderType].some(item => item.ticket === order.ticket);

                if (!isOrderExists) {
                    return {
                        ...state,
                        [orderType]: [...state[orderType], order],
                    };
                }

                return state;
            }

            if (type === "Update") {
                const updatedOrders = state[orderType].map(item => {
                    if (item.ticket === order.ticket) {
                        return order;
                    }
                    return item;
                });

                return {
                    ...state,
                    [orderType]: updatedOrders,
                };
            }

            if (type === "Delete") {
                // TODO: find another solution to keep order until order animation will finish
                const platform = getDevicePlatformFromScreenWidth(window.innerWidth);

                // if (platform === DevicePlatform.Desktop) {
                return {
                    ...state,
                    [orderType]: state[orderType].filter(item => item.ticket !== order.ticket),
                };
                // }
            }

            return state;
        }

        default: {
            return state;
        }
    }
};
