import { useCallback, useEffect, useState } from "react";

const getIncreasedSize = (size: number): number => {
    return size >= 100 ? 100 : Number((size + 0.01).toFixed(2));
};

const getDecreasedSize = (size: number): number => {
    return size <= 0.01 ? 0.01 : Number((size - 0.01).toFixed(2));
};

interface IProps {
    initialValue: number;
    max: number;
    min?: number;
}

interface ILotSize {
    lotSize: number | string;
    handleValueChange: (size: number) => void;
    handleValueIncrease: () => void;
    handleValueDecrease: () => void;
}

export const useLotSize = (props: IProps): ILotSize => {
    const { min, max, initialValue } = props;
    const [lotSize, setLotSize] = useState(initialValue);

    useEffect(() => {
        setLotSize(initialValue || null);
    }, [initialValue]);

    // user input
    const handleValueChange = useCallback(
        (newValue: number): void => {
            let newValueInRange = newValue;

            if (newValue < 0) {
                newValueInRange = 0;
            } else if (newValue > max) {
                newValueInRange = max;
            } else if (min && newValue < min) {
                newValueInRange = min;
            }
            setLotSize(newValueInRange);
        },
        [max, min]
    );

    // increase button
    const handleValueIncrease = useCallback(() => handleValueChange(getIncreasedSize(lotSize)), [
        lotSize,
        handleValueChange,
    ]);

    // decrease button
    const handleValueDecrease = useCallback(() => handleValueChange(getDecreasedSize(lotSize)), [
        lotSize,
        handleValueChange,
    ]);

    return {
        lotSize,
        handleValueChange,
        handleValueIncrease,
        handleValueDecrease,
    };
};
