import {
    AccountActionTypes,
    IAddToFavouritesAction,
    IAddToFavouritesUIAction,
    IFetchFavouritesAction,
    IRemoveFromFavouritesAction,
    IRemoveFromFavouritesUIAction,
    ISetFavouritesAction,
    ISetDevmodeAction,
    ISetLoggedInStateAction,
    ISetStaticAccountInfoAction,
    ISetDynamicAccountInfoAction,
    ISetDynamicInfoCalculatedAction,
} from "@/redux/reducers/accountReducer";
import { IDynamicAccountInfoResponse, IStaticAccountInfoResponse } from "@/hooks/trade/tradeSocket";
import { ITradeNotification } from "@/services/firebase/interfaces";

export const setStaticAccountInfo = (
    accountInfoResponse: IStaticAccountInfoResponse
): ISetStaticAccountInfoAction => ({
    type: AccountActionTypes.SET_STATIC_ACCOUNT_INFO,
    accountInfoResponse,
});

export const setDynamicAccountInfo = (
    accountInfoResponse: IDynamicAccountInfoResponse
): ISetDynamicAccountInfoAction => ({
    type: AccountActionTypes.SET_DYNAMIC_ACCOUNT_INFO,
    accountInfoResponse,
});

export const setDynamicAccountInfoCalculated = (value: boolean): ISetDynamicInfoCalculatedAction => ({
    type: AccountActionTypes.SET_IS_DYNAMIC_INFO_CALCULATED,
    value,
});

export const setLoggedInState = (isGuest: boolean, isLazyMode: boolean): ISetLoggedInStateAction => ({
    type: AccountActionTypes.SET_LOGGED_IN_STATE,
    isGuest,
    isLazyMode,
});

export const fetchFavourites = (): IFetchFavouritesAction => ({
    type: AccountActionTypes.FETCH_FAVOURITES,
});

export const fetchDefaultListOfFavourites = (): IFetchFavouritesAction => ({
    type: AccountActionTypes.FETCH_FAVOURITES,
});

export const setFavourites = (favourites: string[]): ISetFavouritesAction => ({
    type: AccountActionTypes.SET_FAVOURITES,
    favourites,
});

export const addToFavourites = (symbolId?: string): IAddToFavouritesAction => ({
    type: AccountActionTypes.ADD_TO_FAVOURITES,
    symbolId,
});

export const addToFavouritesUI = (symbolId: string | string[]): IAddToFavouritesUIAction => ({
    type: AccountActionTypes.ADD_TO_FAVOURITES_UI,
    symbolId,
});

export const removeFromFavourites = (symbolId: string): IRemoveFromFavouritesAction => ({
    type: AccountActionTypes.REMOVE_FROM_FAVOURITES,
    symbolId,
});

export const removeFromFavouritesUI = (symbolId: string): IRemoveFromFavouritesUIAction => ({
    type: AccountActionTypes.REMOVE_FROM_FAVOURITES_UI,
    symbolId,
});

export const setDevmode = (): ISetDevmodeAction => ({
    type: AccountActionTypes.SET_DEV_MODE,
});

export const setTradeAlerts = (alerts: ITradeNotification[], time: number): any => {
    return {
        type: AccountActionTypes.SET_TRADE_ALERTS,
        alerts,
        time,
    };
};
