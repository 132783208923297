import { useSelector } from "react-redux";

import { IStore } from "../interfaces/IStore";
import { DevicePlatform } from "@/redux/interfaces/IDevice";

export type DeviceState = {
    platform: DevicePlatform,
    isDesktop: boolean,
    isMobile: boolean,
    isTablet: boolean,
}

export const useDevice = (): DeviceState => useSelector((state: IStore) => ({
    platform: state.device.platform,
    isDesktop: state.device.platform === DevicePlatform.Desktop,
    isMobile: state.device.platform === DevicePlatform.Mobile,
    isTablet: state.device.platform === DevicePlatform.Tablet,
}));
