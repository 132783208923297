import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import { Button } from "@mui/material";

import { closeTradeOrderModal } from "@/redux/actions/ui";
import { PressedButtons, useTouchState } from "@/hooks/common/useTouchState";

import styles from "./OrderModal.module.scss";

interface IProps {
    children: any;
    action?: () => void;
    actionLabel?: string;
    onClose?: () => void;
    customFooter?: JSX.Element;
    withCancel?: boolean;
    classes?: {
        closeIcon?: string;
    };
}

const OrderModal = (props: IProps): JSX.Element => {
    const dispatch = useDispatch();
    // const { palette } = useTheme();
    const { t } = useTranslation();
    const { isTouched, buttonName, onTouchStart, onTouchEnd } = useTouchState();

    const {
        children = null,
        customFooter = null,
        actionLabel = null,
        action = null,
        withCancel = false,
        onClose = () => {},
        classes,
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        setIsLoading(true);
        onClose();
        dispatch(closeTradeOrderModal());
    };

    return (
        <div className={clsx(styles.overlay)}>
            <div className={styles.container} ref={containerRef}>
                <div className={styles.header}>
                    <div onClick={handleClose} className={clsx(styles.closeIcon, classes?.closeIcon)} />
                </div>

                <div className={styles.content}>{children}</div>
                <div className={styles.footer}>
                    {customFooter && <div className={clsx(styles.customFooter)}>{customFooter}</div>}
                    {!withCancel && !customFooter ? (
                        <Button
                            className={clsx(styles.actionButton, {
                                [styles.touched]:
                                    isTouched && buttonName === PressedButtons.SINGLE_OTHER,
                            })}
                            variant="contained"
                            onClick={() => action && action()}
                            onTouchStart={() => onTouchStart(PressedButtons.SINGLE_OTHER)}
                            onTouchEnd={onTouchEnd}
                            fullWidth>
                            {actionLabel || ""}
                        </Button>
                    ) : null}
                    {withCancel && !customFooter ? (
                        <div className={clsx(styles.btnContainer)}>
                            <Button
                                className={clsx(styles.actionButton, styles.cancelBtn, {
                                    [styles.touched]: isTouched && buttonName === PressedButtons.CANCEL,
                                })}
                                variant="contained"
                                onClick={handleClose}
                                onTouchStart={() => onTouchStart(PressedButtons.CANCEL)}
                                onTouchEnd={onTouchEnd}
                                fullWidth>
                                {t("cancel")}
                            </Button>

                            <Button
                                className={clsx(styles.actionButton, {
                                    [styles.disabled]: isLoading,
                                    [styles.touched]: isTouched && buttonName === PressedButtons.OTHER,
                                })}
                                disabled={isLoading}
                                variant="contained"
                                onClick={() => {
                                    setIsLoading(true);
                                    action && action();
                                }}
                                onTouchStart={() => onTouchStart(PressedButtons.OTHER)}
                                onTouchEnd={onTouchEnd}
                                fullWidth>
                                {actionLabel || ""}
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default React.memo(OrderModal);
