import { all, fork } from "redux-saga/effects";

import { ordersSaga } from "@/redux/sagas/orders";
import { accountSaga } from "@/redux/sagas/account";

function* rootSaga() {
    yield all([
        fork(ordersSaga),
        fork(accountSaga),
    ]);
}

export default rootSaga;
