import { IAccountState } from "../interfaces/IAccount";
import { IDynamicAccountInfoResponse, IStaticAccountInfoResponse } from "@/hooks/trade/tradeSocket";
import { ITradeNotification } from "@/services/firebase/interfaces";

const initialState: IAccountState = {
    balance: 0,
    credit: 0,
    equity: 0,
    leverage: 1,
    margin: 0,
    freeMargin: 0,
    id: "",
    name: "",
    profit: 0,
    currency: "USD",
    favourites: [],
    accountId: null,
    isGuest: true,
    isLazyMode: false,
    tradingPlatformServerType: "Demo",
    isSetDynamicAccountInfoCalculated: false,
    devmode: false,
    tradeAlerts: [],
    tradeAlertsLastRequestTime: null,
};

export enum AccountActionTypes {
    SET_DYNAMIC_ACCOUNT_INFO = "account/SET_DYNAMIC_ACCOUNT_INFO",
    SET_STATIC_ACCOUNT_INFO = "account/SET_STATIC_ACCOUNT_INFO",
    SET_LOGGED_IN_STATE = "account/SET_LOGGED_IN_STATE",
    FETCH_FAVOURITES = "account/FETCH_FAVOURITES",
    SET_FAVOURITES = "account/SET_FAVOURITES",
    ADD_TO_FAVOURITES = "account/ADD_TO_FAVOURITES",
    ADD_TO_FAVOURITES_UI = "account/ADD_TO_FAVOURITES_UI",
    REMOVE_FROM_FAVOURITES = "account/REMOVE_FROM_FAVOURITES",
    REMOVE_FROM_FAVOURITES_UI = "account/REMOVE_FROM_FAVOURITES_UI",
    SET_IS_DYNAMIC_INFO_CALCULATED = "account/SET_IS_DYNAMIC_INFO_CALCULATED",
    SET_DEV_MODE = "SET_DEV_MODE",
    SET_TRADE_ALERTS = "SET_TRADE_ALERTS",
}

export interface ISetDynamicInfoCalculatedAction {
    type: typeof AccountActionTypes.SET_IS_DYNAMIC_INFO_CALCULATED;
    value: boolean;
}

export interface ISetDynamicAccountInfoAction {
    type: typeof AccountActionTypes.SET_DYNAMIC_ACCOUNT_INFO;
    accountInfoResponse: IDynamicAccountInfoResponse;
}

export interface ISetStaticAccountInfoAction {
    type: typeof AccountActionTypes.SET_STATIC_ACCOUNT_INFO;
    accountInfoResponse: IStaticAccountInfoResponse;
}

export interface ISetLoggedInStateAction {
    type: typeof AccountActionTypes.SET_LOGGED_IN_STATE;
    isGuest: boolean;
    isLazyMode: boolean;
}

export interface IFetchFavouritesAction {
    type: typeof AccountActionTypes.FETCH_FAVOURITES;
}

export interface ISetFavouritesAction {
    type: typeof AccountActionTypes.SET_FAVOURITES;
    favourites: string[];
}

export interface IAddToFavouritesAction {
    type: typeof AccountActionTypes.ADD_TO_FAVOURITES;
    symbolId?: string;
}

export interface IAddToFavouritesUIAction {
    type: typeof AccountActionTypes.ADD_TO_FAVOURITES_UI;
    symbolId: string | string[];
}

export interface IRemoveFromFavouritesAction {
    type: typeof AccountActionTypes.REMOVE_FROM_FAVOURITES;
    symbolId: string;
}

export interface IRemoveFromFavouritesUIAction {
    type: typeof AccountActionTypes.REMOVE_FROM_FAVOURITES_UI;
    symbolId: string;
}

export interface ISetDevmodeAction {
    type: typeof AccountActionTypes.SET_DEV_MODE;
}

export interface ISetTradeAlerts {
    type: typeof AccountActionTypes.SET_TRADE_ALERTS;
    alerts: ITradeNotification[];
    time: number;
}

export type AccountActions =
    | ISetDynamicAccountInfoAction
    | ISetStaticAccountInfoAction
    | ISetLoggedInStateAction
    | IFetchFavouritesAction
    | ISetFavouritesAction
    | IAddToFavouritesAction
    | IAddToFavouritesUIAction
    | IRemoveFromFavouritesAction
    | IRemoveFromFavouritesUIAction
    | ISetDevmodeAction
    | ISetDynamicInfoCalculatedAction
    | ISetTradeAlerts;

export const accountReducer = (state = initialState, action: AccountActions): IAccountState => {
    const { type } = action;

    switch (type) {
        case AccountActionTypes.SET_DYNAMIC_ACCOUNT_INFO: {
            const { accountInfoResponse } = action as ISetDynamicAccountInfoAction;

            const { accountEquity, accountFreeMargin, accountProfit } = accountInfoResponse;

            return {
                ...state,
                ...(accountEquity !== undefined && { equity: accountEquity }),
                ...(accountFreeMargin !== undefined && { freeMargin: accountFreeMargin }),
                ...(accountProfit !== undefined && { profit: accountProfit }),
                isSetDynamicAccountInfoCalculated: true,
            };
        }

        case AccountActionTypes.SET_STATIC_ACCOUNT_INFO: {
            const { accountInfoResponse } = action as ISetStaticAccountInfoAction;

            const {
                accountBalance,
                accountCredit,
                accountLeverage,
                id,
                name,
                accountMargin,
                account,
                tradingPlatformServerType,
            } = accountInfoResponse;

            return {
                ...state,
                balance: accountBalance,
                credit: accountCredit,
                id: id,
                name: name,
                margin: accountMargin,
                leverage: accountLeverage || 1,
                currency: account?.currency || state.currency,
                accountId: id,
                tradingPlatformServerType,
            };
        }

        case AccountActionTypes.SET_LOGGED_IN_STATE: {
            const { isGuest, isLazyMode } = action as ISetLoggedInStateAction;

            return {
                ...state,
                isGuest,
                isLazyMode,
            };
        }

        case AccountActionTypes.SET_FAVOURITES: {
            const { favourites } = action as ISetFavouritesAction;

            return {
                ...state,
                favourites,
            };
        }

        case AccountActionTypes.ADD_TO_FAVOURITES_UI: {
            const { symbolId } = action as IAddToFavouritesUIAction;
            const { favourites } = state;

            if (Array.isArray(symbolId)) {
                return {
                    ...state,
                    favourites: [...favourites, ...symbolId],
                };
            }

            return {
                ...state,
                favourites: [...favourites, symbolId],
            };
        }

        case AccountActionTypes.REMOVE_FROM_FAVOURITES_UI: {
            const { symbolId } = action as IRemoveFromFavouritesUIAction;
            const { favourites } = state;

            return {
                ...state,
                favourites: favourites.filter(f => f !== symbolId),
            };
        }

        case AccountActionTypes.SET_DEV_MODE: {
            return {
                ...state,
                devmode: !state.devmode,
            };
        }

        case AccountActionTypes.SET_IS_DYNAMIC_INFO_CALCULATED: {
            const { value } = action as ISetDynamicInfoCalculatedAction;
            return {
                ...state,
                isSetDynamicAccountInfoCalculated: value,
            };
        }

        case AccountActionTypes.SET_TRADE_ALERTS: {
            const { alerts, time } = action as ISetTradeAlerts;

            return {
                ...state,
                tradeAlerts: alerts,
                tradeAlertsLastRequestTime: time,
            };
        }
    }

    return state;
};
