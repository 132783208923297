import { useState } from "react";

interface ITouchState {
    isTouched: boolean;
    buttonName: string;
    onTouchStart: (event: unknown) => void;
    onTouchEnd: (event: unknown) => void;
}

export enum PressedButtons {
    DEPOSIT = "DEPOSIT",
    WITHDRAWAL = "WITHDRAWAL",
    CANCEL = "Cancel",
    APPLY = "Apply",
    PLACE_ORDER = "PLACE_ORDER",
    DELETE = "DELETE",
    CLOSE = "CLOSE",
    EDIT = "EDIT",
    YES = "YES",
    NO = "NO",
    SINGLE_OTHER = "SINGLE_OTHER",
    OTHER = "OTHER",
}

export const useTouchState = (): ITouchState => {

    const [isTouched, setIsTouched] = useState(false);
    const [buttonName, setButtonName] = useState<PressedButtons>();

    const onTouchStart = (buttonName?: PressedButtons) => {
        if (buttonName) {
            setButtonName(buttonName);
        }
        setIsTouched(true);
    };

    const onTouchEnd = () => {
        if (buttonName) {
            setButtonName(null);
        }
        setIsTouched(false);
    };

    return {
        isTouched,
        buttonName,
        onTouchStart,
        onTouchEnd,
    };
};
