import clsx from "clsx";

import styles from "./Checkbox.module.scss";

interface IProps {
    checked: boolean;
    onChange?: () => void;
    disabled?: boolean;
    classes?: string;
    inputId?: string;
}

const Checkbox = (props: IProps): JSX.Element => {
    const { checked = false, onChange = () => {}, disabled = false, classes = "", inputId = "" } = props;

    const handleChange = () => {
        if (disabled) {
            return;
        }

        onChange();
    };

    return (
        <div
            className={clsx(styles.container, classes, {
                [styles.disabled]: disabled,
                [styles.checked]: checked,
            })}
            onClick={handleChange}
        >
            <input
                type="checkbox"
                id={inputId || null}
                className={styles.input}
                checked={checked}
                onChange={() => {}}
            />
        </div>
    );
};

export default Checkbox;
