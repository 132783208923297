import { DevicePlatform, IDeviceState } from "@/redux/interfaces/IDevice";

const initialState: IDeviceState = {
    platform: DevicePlatform.Desktop,
};

export enum DeviceActionTypes {
    SET_DEVICE_INFO = "device/SET_DEVICE_INFO",
}

export interface ISetDeviceInfoAction {
    type: typeof DeviceActionTypes.SET_DEVICE_INFO;
    deviceInfo: Partial<IDeviceState>;
}

export type DeviceActions =
    | ISetDeviceInfoAction;

export const deviceReducer = (state = initialState, action: DeviceActions): IDeviceState => {
    const { type } = action;

    switch (type) {
        case DeviceActionTypes.SET_DEVICE_INFO: {
            const { deviceInfo } = action as ISetDeviceInfoAction;

            return {
                ...state,
                ...deviceInfo,
            };
        }
    }

    return state;
};
