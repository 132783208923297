import Decimal from 'decimal.js';

const subtract = (number1: number, number2: number): number => {
    if(!number1 || !number2) {
        return 0;
    }
    return new Decimal(number1).minus(new Decimal(number2)).toNumber();
};

const sum = (number1: number, number2: number): number => {
    return new Decimal(number1).plus(new Decimal(number2)).toNumber();
};

const multiply = (number1: number, number2: number): number => {
    return new Decimal(number1).mul(new Decimal(number2)).toNumber();
};

const divide = (number1: number | string, number2: number | string): number => {
    return new Decimal(number1).dividedBy(new Decimal(number2)).toNumber();
};

export { sum, subtract, multiply, divide };
