import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getSymbols, SymbolsInfoResponse, getCategories, getSymbol } from "@/services/trade/symbols";
import { symbolsDataLoaded, quoteDataLoaded } from "@/redux/actions/tradeInfo";
import { setLoggedInState } from "@/redux/actions/account";
import { login, ILoginResponse, setAccessToken } from "@/services/trade/account";
import { getLocationParam } from "@/utils/url";
import { quoteSocketManager } from "./tradeSocket";
import { scheduleManager } from "./schedule";
import { getCurrentBrand, replaceMissingFutureIndexSymbols } from "@/utils/trade";
import { ITradeOrder, getOrders } from "@/services/trade/order";
import { ordersFetched } from "@/redux/actions/orders";
import { retryPromise } from "@/utils/helpers";
import { useAccountInfo } from "@/redux/selectors/accountSelector";

export const useTradeData = (): void => {
    const dispatch = useDispatch();

    const fetchData = async (isAnonymous = false, isLazyMode = false) => {
        const fetchedData = (await Promise.all([
            retryPromise(getSymbols, { retries: 3, delay: 1000 }),
            getCategories(),
            getOrders(),
        ])) as [SymbolsInfoResponse, string[], ITradeOrder[]];

        const [symbols, categoriesFromApi, orders] = fetchedData;

        // FILTERING SYMBOLS
        const symbolsAvailabilityByCategory = Object.keys(symbols).reduce((acc, key) => {
            const name = symbols[key].group.name;

            if (!acc[name]) {
                acc[name] = [];
            }

            acc[name].push(!symbols[key].isHidden);
            return acc;
        }, {});

        const categoriesWithActiveSymbolsOnly = Object.keys(
            symbolsAvailabilityByCategory
        ).filter(category => symbolsAvailabilityByCategory[category].some(value => value));

        const allowedBITASymbols = [
            "BEEL10!",
            "BUEL10!",
            "BEUL10!",
            "BJPL10!",
            "XAUFL5!",
            "BSSFL5!",
            "BUTL10!",
            "BG4L10!",
            "BSPFL5!",
            "BCML10!",
        ];

        const isAllowedSymbol = symbol => {
            // we need exclude category BITA and allow only some specific BITA assets above
            const isAllowedByCategory =
                symbol?.group?.name !== "BITA" ? true : allowedBITASymbols.includes(symbol?.symbol);

            const haveAnyTradableAssets = categoriesWithActiveSymbolsOnly.includes(symbol?.group?.name);
            return isAllowedByCategory && haveAnyTradableAssets;
        };

        let { symbolsInfoById, categories } = Object.keys(symbols).reduce(
            (acc, key) => {
                // exclude category where al assets are closed or not available for trade
                if (isAllowedSymbol(symbols[key]) && !acc.symbolsInfoById[key]) {
                    acc.symbolsInfoById[key] = symbols[key];
                }
                // exclude category where al assets are closed or not available for trade
                if (!acc.categories.includes(symbols[key].group.name) && isAllowedSymbol(symbols[key])) {
                    acc.categories.push(symbols[key].group.name);
                }
                return acc;
            },
            { symbolsInfoById: {}, categories: [] }
        );

        // need to keep same order as API sends
        categories = categoriesFromApi.filter(category => categories.includes(category));

        const missingSymbols = orders.reduce((acc, { symbol }) => {
            if (!symbolsInfoById[symbol] && !acc.includes(symbol)) {
                acc.push(symbol);
            }
            return acc;
        }, []);

        const missingSymbolsInfoById = {};

        if (missingSymbols.length) {
            for (const missingSymbol of missingSymbols) {
                const missingSymbolInfo = await getSymbol(missingSymbol);

                if (!missingSymbolInfo) {
                    continue;
                }

                missingSymbolsInfoById[missingSymbol] = {
                    ...missingSymbolInfo[missingSymbol],
                    isHidden: true,
                };
            }

            symbolsInfoById = {
                ...symbolsInfoById,
                ...missingSymbolsInfoById,
            };
        }

        const parsedOrders = replaceMissingFutureIndexSymbols(orders, Object.keys(symbolsInfoById));
        const quotesInfo = Object.keys(symbolsInfoById).map(symbolId => {
            const symbolInfo = symbolsInfoById[symbolId];
            const { ask = null, bid = null, high = null, low = null } = symbolInfo?.quote || {};

            return {
                id: symbolId,
                ask,
                bid,
                high,
                low,
            };
        });

        scheduleManager.parseTradingPlatformSchedulesData(symbolsInfoById);

        dispatch(setLoggedInState(isAnonymous, isAnonymous && isLazyMode));

        dispatch(symbolsDataLoaded(isAnonymous, symbolsInfoById, categories));

        dispatch(ordersFetched(parsedOrders));

        quoteSocketManager.connect({
            quotesInfo,
            onSuccess: () => dispatch(quoteDataLoaded()),
            dispatch,
        });
    };

    const fetchTradeData = async () => {
        let token = getLocationParam("token");
        let isAnonymous = false;
        let isLazyMode = false;

        const cid = getLocationParam("cid");
        const currency = getLocationParam("currency");

        if (!token) {
            isAnonymous = true;
            isLazyMode = Boolean(cid) && Boolean(currency);
        }

        const response = await retryPromise(
            () => {
                return login({
                    brand: getCurrentBrand(),
                    flag: getLocationParam("flag"),
                    currency,
                    cid,
                    token,
                });
            },
            { retries: 3, delay: 1000, required: "accessToken" }
        );

        const { accessToken } = response as ILoginResponse;
        token = accessToken;
        setAccessToken(token);

        await fetchData(isAnonymous, isLazyMode);
    };

    /**
     * Initial trade data load
     */
    useEffect(() => {
        fetchTradeData();
    }, []);
};

export const useAccountName = (): string => {
    const { id, name = "" } = useAccountInfo();

    return name && /^TA\d+/.test(name) ? name : id || "---";
};
