import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { loadScript } from "@/hooks/chart/helpers";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { setIsChartReady } from "@/redux/actions/ui";

export const useTradeChartLoader = (): void => {
    const dispatch = useDispatch();
    const { platform: devicePlatform } = useDevice();
    const isDesktop = devicePlatform === "Desktop";

    useEffect(() => {
        loadScript(
            `${window?.location.origin}/ChartUI/init/prochart.loader.min.js`,
            () => {
                // @ts-ignore
                const chartLoader = window?.ProChart_Loader();

                chartLoader.init.rootpath = window?.location?.origin;

                chartLoader.init.loadertemplates.push(
                    isDesktop
                        ? {
                            layout: "layout1",
                            template: "default",
                        }
                        : {
                            layout: "layout_mobile",
                            template: "default",
                        }
                );

                chartLoader.LoaderInitComplete = () => {
                    dispatch(setIsChartReady(true));
                };

                chartLoader.LoaderInitCombine();
            },
            "chart_loader_script"
        );
    }, []); // should be loaded only once, on app init
};
