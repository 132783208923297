import { useAccountInfo } from "@/redux/selectors/accountSelector";


interface IProps {
    isGuest: boolean;
    isLazyMode: boolean;
    isLoggedInUser: boolean;
}

export const useLoginInfo = (): IProps => {
    const { isGuest, isLazyMode, devmode } = useAccountInfo();

    return {
        isGuest,
        isLazyMode,
        isLoggedInUser: !(isGuest && !isLazyMode && !devmode)
    };
};
