import { callApi } from "@/services/index";
import { ICommonAPIErrorResponse } from "@/services/common/api";

export const getFavourites = async (): Promise<string[] | ICommonAPIErrorResponse> => {
    const favorites = await callApi({
        endpoint: "/api/favorites",
        method: "GET",
    });

    return favorites && favorites.length ? favorites : [];
};

interface IFavouritesRequest {
    symbols: string[];
}

export const updateFavourites = async (
    favourites: IFavouritesRequest
): Promise<string[] | ICommonAPIErrorResponse> => {
    return await callApi({
        endpoint: "/api/favorites",
        method: "PUT",
        body: favourites,
    });
};

export const resetFavorites= async (): Promise<string[] | ICommonAPIErrorResponse> => {
    return await callApi({
        endpoint: "/api/favorites/default",
        method: "POST",
    });
};

export const getDefaultFavorites = async (): Promise<string[] | ICommonAPIErrorResponse> => {
    // only for demo account
    return await callApi({
        endpoint: "/api/favorites/default",
        method: "GET",
    });
};
