import { quoteSocketManager } from "@/hooks/trade/tradeSocket";

export const getSeenPrice = ({ symbolInfo, operation }) => {
    const { ask, bid } = quoteSocketManager.getValue(symbolInfo.id);
    const price = operation === "Buy" ? ask : bid;

    const pricePrecision = String(Math.round(1 / symbolInfo?.point)).length - 1;
    const priceStringFull = price?.toFixed(pricePrecision);
    const priceMain = priceStringFull?.substr(0, priceStringFull?.length - 1);
    const pip = priceStringFull?.substr(-1, 1);

    return Number(`${priceMain}${pip}`);

    return Number(priceMain);
};
