import { StoreAction } from "@/utils/storeActions";

export enum PopupType {
    None = "[Modal] None",
    Login = "[Modal] Login",
    Intro_0 = "[Modal] Intro_0",
    Intro_1 = "[Modal] Intro_1",
    Intro_2 = "[Modal] Intro_2",
    Intro_3 = "[Modal] Intro_3",
    Intro_4 = "[Modal] Intro_4",
    Intro_5 = "[Modal] Intro_5",
    Intro_6 = "[Modal] Intro_6",
    Intro_7 = "[Modal] Intro_7",
}

export interface IOpenModalAction {
    category: PopupType;
    props?: any;
}

export interface ISetOpenMoveModalAction {
    openMove?: boolean;
}

export type IModalState = IOpenModalAction;

export interface IDispatchOpenModalAction extends IOpenModalAction {
    type: typeof StoreAction.OPEN_POPUP;
}

export interface IDispatchCloseModalAction {
    type: typeof StoreAction.CLOSE_POPUP;
}

export interface IDispatchSetOpenMoveAction extends ISetOpenMoveModalAction {
    type: typeof StoreAction.SET_OPEN_MOVE_POPUP;
}

export type ModalActionTypes =
    | IDispatchOpenModalAction
    | IDispatchCloseModalAction
    | IDispatchSetOpenMoveAction;
