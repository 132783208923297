import React, { useMemo } from "react";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import moment from "moment";
import { useTheme } from "@mui/material";
import { ITradeOrder } from "@/services/trade/order";
import { isTradePlatformNullDate } from "@/utils/trade";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { trimNumberToStepValue } from "@/utils/numbers";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { getDirectionsTranslationKey } from "@/utils/helpers";
import { useRouter } from "next/router";

import styles from "./OrderInfoView.module.scss";

interface IProps {
    volume?: null | string | number;
    order: ITradeOrder;
    operation?: "open" | "close" | "delete" | "delete-confirm";
}

const OrderInfoView: React.FC<IProps> = ({ order, operation, volume }) => {
    const { palette } = useTheme();
    const { locale } = useRouter();
    const isOpenTrade = isTradePlatformNullDate(order?.closeTime);
    const { isDesktop, isMobile } = useDevice();
    const { t } = useTranslation();
    const { symbolsInfoById } = useTradeInfo();
    const symbolInfo = symbolsInfoById[order?.symbol];

    const dataArray = useMemo(() => {
        const data = [
            { label: t("orderId"), value: (order?.ticket && `#${order.ticket}`) || "---" },
            { label: t("lots"), value: volume || order?.lots || "---" },
            {
                label: t("type"),
                value:
                    locale !== "ge"
                        ? t(getDirectionsTranslationKey(order?.type)).toUpperCase()
                        : t(getDirectionsTranslationKey(order?.type)) || "---",
            },
            { label: t("asset"), value: symbolInfo?.ex?.displayName || order?.symbol || "---" },
            {
                label:
                    operation === "delete"
                        ? t("deleted_at_price")
                        : isOpenTrade || operation === "open" || operation === "delete-confirm"
                        ? t("rate")
                        : t("closedAtPrice"),
                value:
                    String(
                        trimNumberToStepValue(
                            isOpenTrade ? order?.openPrice : order?.closePrice,
                            symbolInfo?.point
                        )
                    ) || "---",
            },
        ];

        if (order?.takeProfit) {
            data.push({
                label: t("takeProfit"),
                value: String(trimNumberToStepValue(order.takeProfit, symbolInfo?.point)) || "---",
            });
        }
        if (order?.stopLoss) {
            data.push({
                label: t("stopLoss"),
                value: String(trimNumberToStepValue(order.stopLoss, symbolInfo?.point)) || "---",
            });
        }

        if (order?.expiration && moment(order.expiration).isAfter(moment())) {
            data.push({
                label: t("expirationDate"),
                value: moment(order?.expiration).format("YYYY-MM-DD HH:mm") || "---",
            });
        }

        return data;
    }, [order, isOpenTrade]);

    return (
        <ul className={styles.orderInfoView}>
            {dataArray.map((item, index) => {
                return (
                    <li
                        key={item.label}
                        className={clsx(null, {
                            [styles.rowInfoDesktop]: isDesktop,
                        })}
                        style={{
                            color: (index === 0 || isMobile) && palette.mode === "dark" ? "#ffff" : "",
                        }}>
                        <span className={styles.label}>{item.label}</span>
                        <span>{item.value}</span>
                    </li>
                );
            })}
        </ul>
    );
};

export default React.memo(OrderInfoView);
