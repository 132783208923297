import React from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import moment from "moment";

import InfoList, { IContent as IInfoListContent } from "@/components/Core/InfoList/InfoList";
import { useUI } from "@/redux/selectors/uiSelector";
import { closeTradeOrderModal } from "@/redux/actions/ui";
import { IOrderSuccessModalParams } from "@/redux/interfaces/IUI";
import OrderModal from "../OrderModal";
import OrderInfoView from "@/components/SystemFeedbackMobile/common/OrderInfoView";
import { trimNumberToStepValue } from "@/utils/numbers";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import AudioPlayer, { AudioType } from "@/components/AudioPlayer";
import { getDirectionsTranslationKey } from "@/utils/helpers";

import styles from "./OrderSuccessModal.module.scss";
import { useTradeOrders } from "@/redux/selectors/ordersSelector";
import { removeClosingTicket } from "@/redux/actions/orders";

interface IOrderSuccessModal {
    title?: string;
    actionLabel?: string;
    playAudioEffect?: boolean;
}

const OrderSuccessModal: React.FC<IOrderSuccessModal> = ({
    title,
    actionLabel,
    playAudioEffect = true,
}) => {
    const { palette } = useTheme();
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { tradeOrderModalParams } = useUI();
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const symbolInfo = symbolsInfoById[activeSymbolId];
    const order = (tradeOrderModalParams as IOrderSuccessModalParams)?.order;

    const orderData: IInfoListContent[] = [
        {
            label: "orderId",
            value: (order?.ticket && `#${order.ticket}`) || "---",
        },
        {
            label: "lots",
            value: (order?.lots && `${order?.lots}`) || "---",
        },
        {
            label: "type",
            value: (order?.type && t(getDirectionsTranslationKey(order?.type))) || "---",
        },
        {
            label: "asset",
            value: order?.symbol || symbolInfo?.ex?.displayName || symbolInfo.id || "---",
        },
        {
            label: "rate",
            value: String(trimNumberToStepValue(order?.openPrice, symbolInfo.point)) || "---",
        },
    ];

    if (order?.takeProfit) {
        orderData.push({
            label: "takeProfit",
            value: String(trimNumberToStepValue(order.takeProfit, symbolInfo.point)) || "---",
        });
    }

    if (order?.stopLoss) {
        orderData.push({
            label: "stopLoss",
            value: String(trimNumberToStepValue(order.stopLoss, symbolInfo.point)) || "---",
        });
    }

    if (order?.expiration && moment(order.expiration).isAfter(moment())) {
        orderData.push({
            label: t("expirationDate"),
            value: moment(order?.expiration).format("YYYY-MM-DD HH:mm") || "---",
        });
    }

    return (
        <OrderModal
            action={() => dispatch(closeTradeOrderModal())}
            actionLabel={actionLabel || t("close")}>
            {playAudioEffect && <AudioPlayer type={AudioType.OK} />}
            <div className={styles.iconContainer}>
                <img src="/images/success.svg" alt="success icon" className={styles.successTopIcon} />
            </div>
            <h2 className={styles.title}>{title || t("messages.orderSuccess")}</h2>
            <InfoList
                labelColor={palette.mode === "dark" ? "#89909c" : "#343841"}
                valueColor={palette.mode === "dark" ? "#89909c" : "#343841"}
                content={orderData}
                dense
            />
        </OrderModal>
    );
};

export default OrderSuccessModal;

export const OrderDeleteSuccessModal = (): JSX.Element => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { tradeOrderModalParams } = useUI();
    const successfullyClosedOrder = (tradeOrderModalParams as IOrderSuccessModalParams)?.order;

    const action = () => {
        dispatch(removeClosingTicket(successfullyClosedOrder?.ticket));
        dispatch(closeTradeOrderModal());
    };

    return (
        <OrderModal action={action} actionLabel={t("close")}>
            <AudioPlayer type={AudioType.OK} />
            <div className={styles.iconContainer}>
                <img src="/images/success.svg" alt="success icon" className={styles.successTopIcon} />
            </div>

            <h2 className={styles.title}>{t("successfuly.deleted")}</h2>
            <OrderInfoView order={successfullyClosedOrder} operation="delete" />
        </OrderModal>
    );
};

export const TradeCloseSuccessModal = (): JSX.Element => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { tradeOrderModalParams } = useUI();
    const { volume } = useTradeOrders();
    const successfullyClosedOrder = (tradeOrderModalParams as IOrderSuccessModalParams)?.order;

    const action = () => {
        dispatch(removeClosingTicket(successfullyClosedOrder?.ticket));
        dispatch(closeTradeOrderModal());
    };

    return (
        <OrderModal action={action} actionLabel={t("close")}>
            <AudioPlayer type={AudioType.OK} />
            <div className={styles.iconContainer}>
                <img src="/images/success.svg" alt="success icon" className={styles.successTopIcon} />
            </div>
            <h2 className={styles.title}>{t("successfuly.closed")}</h2>
            <OrderInfoView volume={volume} order={successfullyClosedOrder} />
        </OrderModal>
    );
};
