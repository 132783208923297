import router from "next/router";

import { StoreAction } from "@/utils/storeActions";

import { IModalState, ModalActionTypes, PopupType } from "../interfaces/IModal";

const initialState: IModalState = {
    category: PopupType.None,
    props: {},
};

export const modalReducer = (state = initialState, action: ModalActionTypes | any): IModalState => {
    const { type, category, props, openMove } = action;

    switch (type) {
        case StoreAction.OPEN_POPUP: {
            return { ...state, category, props };
        }

        case StoreAction.CLOSE_POPUP: {
            window.history.replaceState(null, "", router.asPath);
            return initialState;
        }

        case StoreAction.SET_OPEN_MOVE_POPUP: {
            return {
                ...state,
                props: {
                    ...state.props,
                    openMove,
                },
            };
        }
    }
    return state;
};
