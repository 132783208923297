import {
    ISetGeneralErrorAction,
    ISetFavoritesAlertAction,
    ISetNoQoutesAction,
    ISetOrderFailedAction,
    ISetOrderHasWrongPriceAction,
    ISetShowMarginCallAction,
    ISetShowOpenPositionWarningAction,
    ISetShowSecureConnectionAction,
    ISetSuccessfullyClosedOrderAction,
    ISetSuccessfullyDeletedOrderAction,
    ISetSuccessfullyModifiedOrderAction,
    ISetSuccessfullyOpenedOrderAction,
    SystemFeedbackActionTypes,
    ISetNoQoutesLowLiquidityAction, IResetFeedbackModalsAction
} from '@/redux/reducers/systemFeedbackReducer';
import { ITradeOrder } from "@/services/trade/order";
import { IFavoritesAlert } from "../interfaces/ISystemFeedback";

export const setSuccessfullyClosedOrder = (order: ITradeOrder): ISetSuccessfullyClosedOrderAction => ({
    type: SystemFeedbackActionTypes.SET_SUCCESSFULLY_CLOSED_ORDER,
    order,
});

export const setSuccessfullyModifiedOrder = (
    order: ITradeOrder
): ISetSuccessfullyModifiedOrderAction => ({
    type: SystemFeedbackActionTypes.SET_SUCCESSFULLY_MODIFIED_ORDER,
    order,
});

export const setSuccessfullyDeletedOrder = (order: ITradeOrder): ISetSuccessfullyDeletedOrderAction => ({
    type: SystemFeedbackActionTypes.SET_SUCCESSFULLY_DELETED_ORDER,
    order,
});

export const setSuccessfullyOpenedOrder = (order: ITradeOrder): ISetSuccessfullyOpenedOrderAction => ({
    type: SystemFeedbackActionTypes.SET_SUCCESSFULLY_OPENED_ORDER,
    order,
});

export const setShowOpenPositionWarning = (isShow: boolean): ISetShowOpenPositionWarningAction => ({
    type: SystemFeedbackActionTypes.SET_SHOW_OPEN_POSITION_WARNING,
    isShow,
});

export const setGeneralError = (errorMessage: string): ISetGeneralErrorAction => ({
    type: SystemFeedbackActionTypes.SET_GENERAL_ERROR,
    errorMessage,
});

export const setShowSecureConnection = (isShow: boolean): ISetShowSecureConnectionAction => ({
    type: SystemFeedbackActionTypes.SET_SHOW_SECURE_CONNECTION,
    isShow,
});

export const setShowMarginCall = (isShow: boolean): ISetShowMarginCallAction => ({
    type: SystemFeedbackActionTypes.SET_SHOW_MARGIN_CALL,
    isShow,
});

export const setShowNoQuotes = (isShow: boolean): ISetNoQoutesAction => ({
    type: SystemFeedbackActionTypes.SET_SHOW_NO_QUOTES,
    isShow,
});
export const setShowNoQuotesLowLiquidity = (isShow: boolean): ISetNoQoutesLowLiquidityAction => ({
    type: SystemFeedbackActionTypes.SET_SHOW_NO_QUOTES_LOW_LIQUIDITY,
    isShow,
});

export const setShowOrderHasWrongPrice = (isShow: boolean): ISetOrderHasWrongPriceAction => ({
    type: SystemFeedbackActionTypes.SET_SHOW_ORDER_HAS_WRONG_PRICE,
    isShow,
});

export const setShowOrderFailed = (isShow: boolean): ISetOrderFailedAction => ({
    type: SystemFeedbackActionTypes.SET_SHOW_ORDER_FAILED,
    isShow,
});

export const setFavoritesAlertMessage = (value?: IFavoritesAlert): ISetFavoritesAlertAction => ({
    type: SystemFeedbackActionTypes.SET_FAVORITES_ALERT,
    value,
});

export const resetFeedbackModals = (): IResetFeedbackModalsAction => ({
    type: SystemFeedbackActionTypes.RESET_FEEDBACK_MODALS,
});
