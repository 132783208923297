export function getNumberFromString(str) {
    if (!str || (str && typeof str === "number")) {
        return str;
    }

    // Remove any non-digit characters from the string
    const numberString = str.replace(/[^0-9.-]+/g, "");

    // Convert the number string to a number type and return it
    return Number(numberString);
}

export const formatAmount = (amount: number, currency = "", digits?: number): string => {
    if (Number.isNaN(amount) || amount === null) {
        return "---";
    }

    if (!currency || amount === undefined) {
        return `${amount} ${currency}`;
    }

    const isoCodeDisplayCurrencies = ["NZD", "AUD", "CAD"];

    const specificCurrencySignMapping = {
        HKD: "HK$",
        CLP: "CLP",
        MXN: "MX$",
    };

    const formattedValue = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: currency,
        currencyDisplay: isoCodeDisplayCurrencies.includes(currency) ? "code" : "narrowSymbol",
        ...(digits && { minimumFractionDigits: digits, maximumFractionDigits: digits }),
    }).format(Math.abs(amount));

    if (specificCurrencySignMapping[currency]) {
        const formattedAmount = formattedValue.slice(
            2,
            formattedValue.length
        );
        
        if (amount < 0) {
            return `${specificCurrencySignMapping[currency]}${+formattedAmount !== 0 ? "-" : ""}${formattedAmount}`;
        }
        return `${specificCurrencySignMapping[currency]}${formattedValue.slice(
            1,
            formattedValue.length
        )}`;
    }

    if (amount < 0) {
        const currencySymbol = formattedValue.replace(/[0-9,.]/g, '').trim();
        const formattedAmount = formattedValue.replace(currencySymbol, '').trim();
        return `${currencySymbol}${+formattedAmount !== 0 ? "-" : ""}${formattedAmount}`;
    }

    return formattedValue;
};

/**
 * 100000 => 100,000.00
 * can be used when currency formatter cannot be applied
 */
export const formatNumber = (value: number): string => {
    if (Number.isNaN(value) || value === null) {
        return "---";
    }

    return new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
};

// converts 5 to "05"
export const padDate = (value: number): string => (value > 9 ? String(value) : `0${value}`);

/**
 * for example in case price is 1.123, digits is 6
 * result is 1.123000
 * can be used when currency formatter cannot be applied
 */
export const normalyzeDecimalLength = (price: string | number, digits: number): string => {
    price = String(price).trim();

    let decimalPart;

    if (price.includes(".")) {
        decimalPart = price.split(".")[1];
    } else if (price.includes(",")) {
        decimalPart = price.split(",")[1];
    }

    if (decimalPart && digits > decimalPart.length) {
        return `${price}${Array(digits - decimalPart.length)
            .fill(0)
            .join("")}`;
    }
    return price;
};
