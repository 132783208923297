import React from "react";
import { Dispatch } from "react-redux";

import { ITradeOrderModalTypes } from "@/redux/interfaces/IUI";
import { useUI } from "@/redux/selectors/uiSelector";
import { CPluginErrorCodes } from "@/services/index";
import { openTradeOrderModal } from "@/redux/actions/ui";
import OrderSuccessModal, {
    OrderDeleteSuccessModal,
    TradeCloseSuccessModal,
} from "./Success/OrderSuccessModal";
import OrderErrorModal, {
    InvalidParamsModal,
    InvalidSLTPModal,
    OrderDeleteConfirmModal,
    NoConnectionModal,
    OpenPositionModal,
    NoQuotesModal,
    OrderWithWrongPriceModal,
    OrderFailedModal,
    TradeDisabledModal,
} from "./Error/OrderErrorModal";
import { OrderCloseConfirmModal } from "./Confirm/ConfirmModal";
import DepositModal, { MarginCallModal, MarketClosedModal } from "./Deposit/DepositModal";
import { useTranslation } from "next-i18next";
import { setShowNoQuotes, setShowNoQuotesLowLiquidity } from "@/redux/actions/systemFeedback";

interface IGetOrderErrorModalProps {
    dispatch: Dispatch;
    code: CPluginErrorCodes;
    message: string;
    operationStartTimestamp?: number;
}
// eslint-disable-next-line
export const getModalType = (code: CPluginErrorCodes, message?: string): ITradeOrderModalTypes => {
    const DEFAULT_ERROR_MODAL = ITradeOrderModalTypes.ORDER_ERROR;
    let modalType;

    if (code) {
        switch (code) {
            case CPluginErrorCodes.TradeBadStops:
                modalType = ITradeOrderModalTypes.INVALID_SL_TP;
                break;

            case CPluginErrorCodes.TradeMarketClosed:
                modalType = ITradeOrderModalTypes.MARKET_CLOSED;
                break;

            case CPluginErrorCodes.TradeDisable:
                modalType = ITradeOrderModalTypes.TRADE_DISABLED;
                break;

            case CPluginErrorCodes.TradeDisableReadOnly:
                modalType = ITradeOrderModalTypes.TRADE_DISABLED_USER_READ_ONLY;
                break;

            case CPluginErrorCodes.TradeDisableCloseOnly:
                modalType = ITradeOrderModalTypes.TRADE_DISABLED_ASSET_CLOSE_ONLY;
                break;

            case CPluginErrorCodes.TradeDisableExpiredContract:
                modalType = ITradeOrderModalTypes.TRADE_DISABLED_EXPIRED_CONTRACT;
                break;

            case CPluginErrorCodes.TradeDisableLowLiquidity:
                modalType = ITradeOrderModalTypes.NO_QUOTES_LOW_LIQUIDITY;
                break;

            case CPluginErrorCodes.InvalidParams:
                modalType = ITradeOrderModalTypes.INVALID_PARAMS;
                break;

            case CPluginErrorCodes.InvalidParamsWrongExpireDate:
                modalType = ITradeOrderModalTypes.INVALID_PARAMETERS_WRONG_EXPIRE_DATE;
                break;

            case CPluginErrorCodes.NoConnect:
                modalType = ITradeOrderModalTypes.NO_CONNECT;
                break;

            case CPluginErrorCodes.OpenPosition:
                modalType = ITradeOrderModalTypes.OPEN_POSITION;
                break;

            case CPluginErrorCodes.TradeNoMoney:
                modalType = ITradeOrderModalTypes.INSUFFICIENT_FUNDS;
                break;

            case CPluginErrorCodes.TradeBadPrices:
                modalType = ITradeOrderModalTypes.ORDER_WITH_WRONG_PRICES;
                break;

            case CPluginErrorCodes.TradeOffquotes:
                modalType = ITradeOrderModalTypes.NO_QUOTES;
                break;

            case CPluginErrorCodes.TradeDisabledUserReadOnly:
                modalType = ITradeOrderModalTypes.TRADE_DISABLED_USER_READ_ONLY;
                break;

            case CPluginErrorCodes.TradeDisabledAssetCloseOnly:
                modalType = ITradeOrderModalTypes.TRADE_DISABLED_ASSET_CLOSE_ONLY;
                break;

            case CPluginErrorCodes.RequestPlaced:
                modalType = ITradeOrderModalTypes.REQUEST_PLACED;
                break;
            default:
                modalType = DEFAULT_ERROR_MODAL;
                break;
        }
    }
    return modalType;
};

export const openOrderErrorModal = ({ dispatch, code, message }: IGetOrderErrorModalProps): void => {
    const modalType = getModalType(code, message);

    dispatch(openTradeOrderModal(modalType, { error: message }));
    // custom mobile error modals
    if (code) {
        switch (code) {
            case CPluginErrorCodes.TradeOffquotes:
                dispatch(setShowNoQuotes(true));
                break;
            case CPluginErrorCodes.TradeDisableLowLiquidity:
                dispatch(setShowNoQuotesLowLiquidity(true));
                break;
            default:
                break;
        }
    }
};

const TradeOrderModal = (): JSX.Element => {
    const { tradeOrderModal } = useUI();
    const { t } = useTranslation("common");

    switch (tradeOrderModal) {
        case ITradeOrderModalTypes.OPEN_ORDER_SUCCESS:
            return <OrderSuccessModal title={t("successfuly.opened")} />;
        case ITradeOrderModalTypes.INSUFFICIENT_FUNDS:
            return <DepositModal />;
        case ITradeOrderModalTypes.MARKET_CLOSED:
            return <MarketClosedModal />;

        case ITradeOrderModalTypes.TRADE_DISABLED:
            return <TradeDisabledModal />;
        case ITradeOrderModalTypes.TRADE_DISABLED_USER_READ_ONLY:
            return <TradeDisabledModal type={"ReadOnly"} />;
        case ITradeOrderModalTypes.TRADE_DISABLED_ASSET_CLOSE_ONLY:
            return <TradeDisabledModal type={"CloseOnly"} />;
        case ITradeOrderModalTypes.TRADE_DISABLED_EXPIRED_CONTRACT:
            return <TradeDisabledModal type={"ExpiredContract"} />;

        case ITradeOrderModalTypes.INVALID_SL_TP:
            return <InvalidSLTPModal />;
        case ITradeOrderModalTypes.INVALID_PARAMS:
            return <InvalidParamsModal />;
        case ITradeOrderModalTypes.INVALID_PARAMETERS_WRONG_EXPIRE_DATE:
            return <InvalidParamsModal type={"InvalidExpirationDate"} />;
        case ITradeOrderModalTypes.ORDER_DELETE_CONFIRM:
            return <OrderDeleteConfirmModal />;
        case ITradeOrderModalTypes.ORDER_DELETE_SUCCESS:
            return <OrderDeleteSuccessModal />;
        case ITradeOrderModalTypes.MARGIN_CALL:
            return <MarginCallModal />;
        case ITradeOrderModalTypes.OPEN_POSITION:
            return <OpenPositionModal />;
        case ITradeOrderModalTypes.CLOSE_TRADE:
            return <OrderCloseConfirmModal />;
        case ITradeOrderModalTypes.CLOSE_TRADE_SUCCESS:
            return <TradeCloseSuccessModal />;
        case ITradeOrderModalTypes.ORDER_MODIFIED_SUCCESS:
            return <OrderSuccessModal title={t("successfuly.modified")} />;
        case ITradeOrderModalTypes.NO_CONNECT:
            return <NoConnectionModal />;

        case ITradeOrderModalTypes.NO_QUOTES:
            return <NoQuotesModal />;
        case ITradeOrderModalTypes.NO_QUOTES_LOW_LIQUIDITY:
            return <NoQuotesModal type={"LowLiquidity"} />;

        case ITradeOrderModalTypes.ORDER_WITH_WRONG_PRICES:
            return <OrderWithWrongPriceModal />;
        case ITradeOrderModalTypes.ORDER_FAILED:
            return <OrderFailedModal />;
        case ITradeOrderModalTypes.ORDER_ERROR:
            return <OrderErrorModal />;
        case ITradeOrderModalTypes.REQUEST_PLACED:
            return <OrderErrorModal placed icon="success" />;

        default:
            return null;
    }
};

export default TradeOrderModal;
