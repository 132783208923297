import { IAppConfiguration } from "../interfaces/IAppConfiguration";

const initialState: IAppConfiguration = {
    socketUrl: "",
    siteLanguages: "",
    apiUrl: "",
};

export enum AppConfigurationTypes {
    UPDATE = "update",
}

export interface IDispatchAppConfigurationAction {
    type: typeof AppConfigurationTypes.UPDATE;
    data: IAppConfiguration;
}

export type AppConfigurationAction = IDispatchAppConfigurationAction;

export const appConfigurationReducer = (state = initialState, action: AppConfigurationAction): IAppConfiguration => {
    const { type, data } = action;
    switch (type) {
        case AppConfigurationTypes.UPDATE: {
            return { ...state, ...data };
        }
    }
    return state;
};
