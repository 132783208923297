import { createWrapper } from "next-redux-wrapper";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../saga";

import { appConfigurationReducer } from "./reducers/appConfigurationReducer";
import { deviceReducer } from "./reducers/deviceReducer";
import { modalReducer } from "./reducers/modalReducer";
import { ordersReducer } from "./reducers/ordersReducer";
import { tradeInfoReducer } from "./reducers/tradeInfoReducer";
import { uiReducer } from "./reducers/uiReducer";
import { accountReducer } from "./reducers/accountReducer";
import { systemFeedbackReducer } from "./reducers/systemFeedbackReducer";

const reducers = combineReducers({
    appConfiguration: appConfigurationReducer,
    device: deviceReducer,
    modal: modalReducer,
    trade: tradeInfoReducer,
    orders: ordersReducer,
    ui: uiReducer,
    account: accountReducer,
    systemFeedback: systemFeedbackReducer,
});

const bindMiddleware = middleware => {
    if (process.env.NODE_ENV !== "production") {
        return composeWithDevTools(applyMiddleware(...middleware));
    }

    return applyMiddleware(...middleware);
};

const makeStore = ({ isServer }) => {
    const sagaMiddleware = createSagaMiddleware();

    if (isServer) {
        return createStore(reducers, bindMiddleware([sagaMiddleware]));
    } else {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { persistStore, persistReducer } = require("redux-persist");
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const storage = require("redux-persist/lib/storage").default;

        const persistConfig = {
            key: "nextjs",
            whitelist: ["account", "balance"],
            autoRehydrate: true,
            purgeOnLost: true,
            storage,
        };

        const persistedReducer = persistReducer(persistConfig, reducers);

        const store = createStore(persistedReducer, bindMiddleware([sagaMiddleware]));

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        store.__persistor = persistStore(store);

        // @ts-ignore
        store.sagaTask = sagaMiddleware.run(rootSaga);

        return store;
    }
};

// Export the wrapper & wrap the pages/_app.js with this wrapper only
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const wrapper = createWrapper(makeStore);
