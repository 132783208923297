import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAnimationParams } from "@/redux/actions/ui";
import { useUI } from "@/redux/selectors/uiSelector";
import { ITradeOrder } from "@/services/trade/order";

import 'animate.css';

export enum AnimationClasses {
    fadeInLeft = "animate__animated animate__fadeInLeft animate__faster",
    backOutRight = "animate__animated animate__backOutRight",
    fadeInUp = "animate__animated animate__fadeInUp",
    fadeIn = "animate__animated animate__fadeIn",
    fadeOut = "animate__animated animate__fadeOut animate__faster",
}

export interface ISetAnimationParams {
    class: AnimationClasses,
    params?: Partial<ITradeOrder>,
}

interface IProps {
    setAnimation: (type: ISetAnimationParams) => void,
    animationParams: ISetAnimationParams,
    setAnimationModal: (type: AnimationClasses) => void;
    animationModalClass: AnimationClasses,
}

export const useAnimation = (): IProps => {
    const dispatch = useDispatch();
    const { animationParams } = useUI();

    const [animationModalClass, setAnimationModalClass] = useState<AnimationClasses>();

    const setAnimation = (data: ISetAnimationParams | null) => {
        dispatch(setAnimationParams(data));

        setTimeout(() => {
            dispatch(setAnimationParams(null));
        }, 500);
    };

    const setAnimationModal = (value: AnimationClasses) => {
        setAnimationModalClass(value);

        setTimeout(() => {
            setAnimationModalClass(null);
        }, 500);
    };

    return {
        setAnimation,
        animationParams,
        
        setAnimationModal,
        animationModalClass,
    };
};
