export const removeEmpty = (obj: Record<string, unknown>): Record<string, unknown> => {
    Object.keys(obj).forEach(key => ([undefined, null].includes(obj[key]) ? delete obj[key] : {}));

    return obj;
};

// removes duplicates in array
export const unique = <T>(arr: Array<T>): Array<T> => [...new Set(arr)];

export const uniqueBy = <T>(arr: Array<T>, cond: (a: T, b: T) => boolean): Array<T> => {
    return arr.filter((item, index) => arr.findIndex(item2 => cond(item, item2)) === index);
};

export const randomNumberInRange = (min: number, max: number): number =>
    Math.random() * (max - min) + min;

export const isServerSide = (): boolean => typeof window === "undefined";

export const removeNonWhitelistedChars = (input: string, whitelist: string): string => {
    const regex = new RegExp("[^" + whitelist + "]", "g");

    return input.replace(regex, "");
};

export const generateCookieKey = (keys: string[]) => {
    return keys.join("_");
};
