import { createContext, useRef } from "react";
import { ITradeChartAPI, TradeChartPriceType, TradeChartTimeframe } from "@/hooks/chart";

const callRefFn = (ref, ...args) => {
    if (!ref.current) {
        return;
    }

    return ref.current(...args);
};

interface ITradeChartGlobalAPI {
    changeTimeframe: (t: TradeChartTimeframe) => void;
    changePriceType: (p: TradeChartPriceType) => void;
    addStopLossLine: (price: number) => void;
    removeStopLossLine: () => void;
    addTakeProfitLine: (price: number) => void;
    removeTakeProfitLine: () => void;
    updateGlobalChartAPI: (props: ITradeChartAPI) => void;
}

export const useTradeChartGlobalAPI = (): ITradeChartGlobalAPI => {
    const changeTimeframeRef = useRef(null);
    const changePriceTypeRef = useRef(null);
    const addStopLossLineRef = useRef(null);
    const removeStopLossLineRef = useRef(null);
    const addTakeProfitLineRef = useRef(null);
    const removeTakeProfitLineRef = useRef(null);

    return {
        updateGlobalChartAPI: (props: ITradeChartAPI) => {
            const {
                changeTimeframe,
                changePriceType,
                addStopLossLine,
                removeStopLossLine,
                addTakeProfitLine,
                removeTakeProfitLine,
            } = props;

            changeTimeframeRef.current = changeTimeframe;
            changePriceTypeRef.current = changePriceType;
            addStopLossLineRef.current = addStopLossLine;
            removeStopLossLineRef.current = removeStopLossLine;
            addTakeProfitLineRef.current = addTakeProfitLine;
            removeTakeProfitLineRef.current = removeTakeProfitLine;
        },
        changeTimeframe: (t: TradeChartTimeframe) => callRefFn(changeTimeframeRef, t),
        changePriceType: (p: TradeChartPriceType) => callRefFn(changePriceTypeRef, p),
        addStopLossLine: (price: number) => callRefFn(addStopLossLineRef, price),
        removeStopLossLine: () => callRefFn(removeStopLossLineRef),
        addTakeProfitLine: (price: number) => callRefFn(addTakeProfitLineRef, price),
        removeTakeProfitLine: () => callRefFn(removeTakeProfitLineRef)
    };
};

export const TradeChartGlobalAPIContext = createContext<ITradeChartGlobalAPI>(null);

TradeChartGlobalAPIContext.displayName = "TradeChartGlobalAPIContext";
