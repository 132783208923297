import { ICommonAPIErrorResponse } from "@/services/common/api";
import { callApi } from "@/services/index";

export type SymbolMarginCalculationMode = "Forex" | "CFD" | "CFDLeverage";

export type SymbolProfitCalculationMode = "Forex" | "CFD" | "CFDLeverage";

export type SymbolTradeMode = "Full" | "No" | "Close";

export interface ISymbolInfo {
    id: string; // added on client for convenience
    digits: number;
    point: number;
    contractSize: number;
    currency: string;
    percentage: number;
    ex: {
        displayName: string;
        description: string;
        leverage: number;
        spreadType: string;
        swapBuy: number;
        swapSell: number;
        marginCalculationMode: SymbolMarginCalculationMode;
        profitCalculationMode: SymbolProfitCalculationMode;
        rolloverDate: string | null;
        stopsLevel: number;
        tradeMode: SymbolTradeMode;
    };
    group: {
        name: string;
        lotMin: number;
        lotMax: number;
        lotStep: number;
    };
    quote: {
        bid: number;
        ask: number;
        high: number;
        low: number;
        midnightPrice?: number;
        time: string;
    };
    schedule: {
        mon: string[];
        tue: string[];
        wen: string[];
        thu: string[];
        fri: string[];
        sat: string[];
        sun: string[];
    };
    sentiments: {
        bull: string;
        bear: string;
        correlation: number; // 0.75 means bull is 75%, bear is 25%
    };
    isHidden?: boolean;
}

export type SymbolsInfoResponse = Record<string, ISymbolInfo>;

export const getSymbols = async (): Promise<SymbolsInfoResponse | ICommonAPIErrorResponse> => {
    return await callApi({
        endpoint: "/api/symbols",
        method: "GET",
    });
};

export const getSymbol = async (symbolId: string): Promise<ISymbolInfo | ICommonAPIErrorResponse> => {
    const response = await callApi({
        endpoint: `/api/symbols/${symbolId}`,
        method: "GET",
    });

    if (response && !response.isFailed) {
        return response;
    }

    return null;
};

export const getCategories = async (): Promise<string[] | ICommonAPIErrorResponse> => {
    return await callApi({
        endpoint: "/api/symbols/categories",
        method: "GET",
    });
};

export interface ISymbolQuoteHistory {
    high: number;
    low: number;
    open: number;
    close: number;
    volume: number;
    time: string;
}

// TODO: remove here and from redux, if we don't get the updated history API
export type SymbolsQuoteHistoryResponse = Record<string, ISymbolQuoteHistory[]>;

interface ISymbolQuoteHistoryParams {
    symbol: string;
    timeframe: HistoryTimeframe;
    count?: number;
    date?: string;
}

export enum HistoryTimeframe {
    M1 = "M1",
    M5 = "M5",
    M15 = "M15",
    M30 = "M30",
    H1 = "H1",
    H4 = "H4",
    D1 = "D1",
    W1 = "W1",
    MN1 = "MN1",
}

export interface ISymbolQuoteHistoryResponse {
    quoteHistory: {
        data: ISymbolQuoteHistory[];
        symbol: string;
    };
    isFailed?: boolean;
    status?: number;
}

export const getSymbolQuoteHistory = async (
    params: ISymbolQuoteHistoryParams
): Promise<ISymbolQuoteHistoryResponse | ICommonAPIErrorResponse> => {
    const { symbol, timeframe, ...query } = params;

    return await callApi({
        endpoint: `/api/quote-history/${symbol}/${timeframe}`,
        method: "GET",
        queryParams: query,
    });
};

export const getSymbolQuoteHistory_Old = async (
    params: ISymbolQuoteHistoryParams
): Promise<ISymbolQuoteHistoryResponse | ICommonAPIErrorResponse> => {
    const { symbol, timeframe, ...query } = params;

    return await callApi({
        endpoint: `/api/quote-history/old/${symbol}/${timeframe}`,
        method: "GET",
        queryParams: query,
    });
};
