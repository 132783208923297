import {
    ICloseTradeOrderModalAction,
    IOpenTradeOrderModalAction,
    ISetIsAssetsTableExpandedAction,
    ISetTradeOrderViewAction,
    UIActionTypes,
    ISetAssetsTabActiveAction,
    ISetTradesTabActiveAction,
    ISetWalletTabActiveAction,
    ISetToggleExpandFooterAction,
    ISetToggleExpandFooterFullScreenAction,
    ISetOrderModifyPopupOpen,
    ISetOrderModifyPopupRequestPending,
    ISetToggleMobileFooterAction,
    ISetIsTradingHistoryOpenAction,
    ISetIsOpenTradesOpenAction,
    ISetIsPendingOrdersOpenAction,
    IOpenSingleAssetView,
    IToggleMobileAccountInfo,
    IToggleMobilePendingOrders,
    ISetToggleMobileHeaderAction,
    IMessageFavoriteAddedAction,
    IMessageFavoriteRemovedAction,
    ISetIsChartReadyAction,
    ISetIsFirstRenderAnimationFinishedAction,
    ISetAnimationMobileType,
    ISetChartTab,
    ILockExpandButtonAction,
    IToggleNotificationsPopup,
    IScrollToCoordinatesAction,
    IIsTradeAlertsLoading,
} from "../reducers/uiReducer";
import { ChartTabs, ITradeOrderModalTypes, TradeOrderModalParamTypes } from "../interfaces/IUI";
import { ISetAnimationParams } from "@/hooks/common/useAnimation";

export const setTradeOrderView = (isOpen: boolean): ISetTradeOrderViewAction => ({
    type: UIActionTypes.SET_TRADE_ORDER_VIEW,
    isOpen,
});

export const setIsChartReady = (isReady: boolean): ISetIsChartReadyAction => ({
    type: UIActionTypes.SET_IS_CHART_READY,
    isReady,
});

export const setIsFirstRenderAnimationFinishedChartReady = (
    isReady: boolean
): ISetIsFirstRenderAnimationFinishedAction => ({
    type: UIActionTypes.SET_IS_FIRST_RENDER_ANIMATION_FINISHED,
    isReady,
});

export const setIsAssetsTableExpanded = (isExpanded: boolean): ISetIsAssetsTableExpandedAction => ({
    type: UIActionTypes.SET_IS_ASSETS_TABLE_EXPANDED,
    isExpanded,
});

export const openTradeOrderModal = (
    modalType: ITradeOrderModalTypes,
    params?: TradeOrderModalParamTypes
): IOpenTradeOrderModalAction => {
    return {
        type: UIActionTypes.OPEN_TRADE_ORDER_MODAL,
        modalType,
        params,
    };
};

export const closeTradeOrderModal = (
    params?: TradeOrderModalParamTypes
): ICloseTradeOrderModalAction => ({
    type: UIActionTypes.CLOSE_TRADE_ORDER_MODAL,
    params,
});

export const setAssetsTabActive = (): ISetAssetsTabActiveAction => ({
    type: UIActionTypes.MOBILE_SET_IS_ASSETS_TAB_ACTIVE,
});

export const setTradesTabActive = (): ISetTradesTabActiveAction => ({
    type: UIActionTypes.MOBILE_SET_IS_TRADES_TAB_ACTIVE,
});

export const setWalletTabActive = (): ISetWalletTabActiveAction => ({
    type: UIActionTypes.MOBILE_SET_IS_WALLET_TAB_ACTIVE,
});

export const setToggleExpandFooter = (value): ISetToggleExpandFooterAction => ({
    type: UIActionTypes.TOGGLE_EXPAND_FOOTER,
    value,
});

export const setToggleExpandFooterFullScreen = (
    isFullscreen: boolean
): ISetToggleExpandFooterFullScreenAction => ({
    type: UIActionTypes.TOGGLE_EXPAND_FOOTER_FULL_SCREEN,
    isFullscreen,
});

export const setIsOpenTradesOpen = (isOpen: boolean): ISetIsOpenTradesOpenAction => ({
    type: UIActionTypes.SET_IS_OPEN_TRADES_OPEN,
    isOpen,
});

export const setIsPendingOrdersOpen = (isOpen: boolean): ISetIsPendingOrdersOpenAction => {
    return {
        type: UIActionTypes.SET_IS_PENDING_ORDERS_OPEN,
        isOpen,
    };
};

export const setIsTradingHistoryOpen = (isOpen: boolean): ISetIsTradingHistoryOpenAction => ({
    type: UIActionTypes.SET_IS_TRADING_HISTORY_OPEN,
    isOpen,
});

export const setOrderModifyPopupOpen = (isOpen: boolean): ISetOrderModifyPopupOpen => ({
    type: UIActionTypes.SET_ORDER_MODIFY_POPUP_OPEN,
    isOpen,
});

export const setOrderModifyPopupRequestPending = (
    isPending: boolean
): ISetOrderModifyPopupRequestPending => ({
    type: UIActionTypes.SET_ORDER_MODIFY_POPUP_REQUEST_PENDING,
    isPending,
});

export const setToggleMobileFooter = (value: boolean): ISetToggleMobileFooterAction => ({
    type: UIActionTypes.TOGGLE_MOBILE_FOOTER,
    value,
});

export const setToggleMobileHeader = (value: boolean): ISetToggleMobileHeaderAction => ({
    type: UIActionTypes.TOGGLE_MOBILE_HEADER,
    value,
});

export const setSingleAssetViewActive = (): IOpenSingleAssetView => ({
    type: UIActionTypes.MOBILE_SET_IS_SINGLE_ASSET_VIEW_ACTIVE,
    isOpen: true,
});

export const setMessageFavoriteAdded = (value: string): IMessageFavoriteAddedAction => ({
    type: UIActionTypes.SET_ALERT_MESSAGE_FAVORITE_ADDED,
    value,
});

export const setMessageFavoriteRemoved = (value: string): IMessageFavoriteRemovedAction => ({
    type: UIActionTypes.SET_ALERT_MESSAGE_FAVORITE_REMOVED,
    value,
});
export const toggleMobileAccountInfo = (value: boolean): IToggleMobileAccountInfo => ({
    type: UIActionTypes.TOGGLE_MOBILE_ACCOUNT_INFO,
    isOpen: value,
});

export const toggleMobilePendingOrders = (value: boolean): IToggleMobilePendingOrders => ({
    type: UIActionTypes.TOGGLE_MOBILE_PENDING_ORDERS,
    isOpen: value,
});

export const setAnimationParams = (params?: ISetAnimationParams): ISetAnimationMobileType => ({
    type: UIActionTypes.SET_ANIMATION,
    params,
});

export const setChartTab = (value: ChartTabs): ISetChartTab => ({
    type: UIActionTypes.SET_CHART_TAB,
    value,
});

export const setLockExpandButton = (value: boolean): ILockExpandButtonAction => ({
    type: UIActionTypes.LOCK_EXPAND_BUTTON,
    value,
});

export const toggleNotificationsPopup = (value: boolean): IToggleNotificationsPopup => ({
    type: UIActionTypes.NOTIGICATIONS_TOGGLE,
    value,
});

export const assetListScrollToCoordinate = (asset: string | null): IScrollToCoordinatesAction => ({
    type: UIActionTypes.SCROLL_TO_COORDINATES,
    asset,
});

export const setIsTradeAlertsLoading = (isLoading: boolean): IIsTradeAlertsLoading => ({
    type: UIActionTypes.TRADE_ALERTS_LOADING,
    isLoading,
});
