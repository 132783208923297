import { SymbolsInfoResponse } from "@/services/trade/symbols";

import {
    IAccountDataLoadedAction,
    IQuoteDataLoadedAction,
    ISetActiveGroupNameAction,
    ISetActiveOrderAction,
    ISetActiveSymbolAction,
    ISetActiveSymbolFromURLAction,
    ISetSearchAction,
    ISymbolsDataLoadedAction,
    TradeInfoActionTypes,
} from "../reducers/tradeInfoReducer";

export const setActiveSymbol = (id: string, point: number, internalSymbolId?: string): ISetActiveSymbolAction => ({
    type: TradeInfoActionTypes.SET_ACTIVE_SYMBOL,
    id,
    point,
    internalSymbolId: internalSymbolId || null
});

export const setActiveOrder = (id: number): ISetActiveOrderAction => ({
    type: TradeInfoActionTypes.SET_ACTIVE_ORDER,
    id,
});

export const symbolsDataLoaded = (
    isAnonymousMode: boolean,
    symbolsInfoById: SymbolsInfoResponse,
    categories: string[]
): ISymbolsDataLoadedAction => ({
    type: TradeInfoActionTypes.SYMBOLS_DATA_LOADED,
    isAnonymousMode,
    symbolsInfoById,
    categories,
});

export const quoteDataLoaded = (): IQuoteDataLoadedAction => ({
    type: TradeInfoActionTypes.QUOTE_DATA_LOADED,
});

export const setSearch = (search: string): ISetSearchAction => ({
    type: TradeInfoActionTypes.SET_SEARCH,
    search,
});

export const setActiveGroupName = (activeGroupName: string): ISetActiveGroupNameAction => ({
    type: TradeInfoActionTypes.SET_ACTIVE_GROUP_NAME,
    activeGroupName,
});

export const setAccountDataLoaded = (): IAccountDataLoadedAction => ({
    type: TradeInfoActionTypes.SET_ACCOUNT_DATA_LOADED,
});

export const setActiveSymbolFromURL = (isActive: boolean): ISetActiveSymbolFromURLAction => ({
    type: TradeInfoActionTypes.SET_ACTIVE_SYMBOL_FROM_URL,
    isActive,
});
