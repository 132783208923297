import React from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";

import { useDevice } from "@/redux/selectors/deviceSelector";
import { useUI } from "@/redux/selectors/uiSelector";
import InfoListValue from "./components/InfoListValue";
import { getIconPath } from "@/utils/helpers";

import styles from "./InfoList.module.scss";


export type ValueType = "price" | "number" | "text";

export interface IContent {
    label: string;
    value: string;
    valueType?: ValueType;
}

interface IInfoListProps {
    title?: string;
    titleIcon?: string;
    content: IContent[];
    dense?: boolean;
    striped?: boolean;
    glossary?: boolean;
    description?: boolean;
    decreased?: boolean;
    labelColor?: string | null;
    valueColor?: string | null;
    className?: string;
    trading_hours?: boolean;
}

const InfoList: React.FC<IInfoListProps> = ({
    title = "",
    titleIcon,
    dense = false,
    striped = true,
    glossary = false,
    description = false,
    content,
    decreased,
    labelColor = null,
    valueColor = null,
    className,
}) => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const { isDesktop, isMobile } = useDevice();
    const { isTradeOrderViewOpen, isAssetsTableExpanded } = useUI();

    const isNarrow = isTradeOrderViewOpen && isAssetsTableExpanded;
    const iconPath = titleIcon || getIconPath({ name: "info_bold", themeMode: palette.mode });

    return (
        <div className={clsx(styles.container, className)}>
            {title && (
                <div className={styles.heading}>
                    <img src={iconPath} alt="Info icon" />
                    <h3>{title ? t(title) : ""}</h3>
                </div>
            )}

            <div
                className={clsx(null, {
                    [styles.content]: !isMobile,
                    [styles.content_mobile]: isMobile,
                    [styles.striped]: striped,
                    [styles.striped_mobile]: isMobile,
                    [styles.striped_informaiton]: !isMobile && title === "information",
                    [styles.dense]: dense,
                    [styles.glossary]: glossary,
                    [styles.description]: description,
                    [styles.maxHeight]: title === "pendingTrades" && !isNarrow && isDesktop,
                })}
            >
                {content.map(({ label, value, valueType }, index) => {
                    const forcedLabelColor = label === "orderId" && palette.mode === "dark" ? "#fffff" : labelColor;
                    const forcedValueColor = label === "orderId" && palette.mode === "dark" ? "#fffff" : valueColor;

                    return (
                        <div
                            className={clsx(null, {
                                [styles.row]: !isMobile,
                                [styles.row_mobile]: !glossary && isMobile,
                                [styles.row_mobileGlossary]: glossary && isMobile,
                                [styles.decreased]: decreased,
                            })}
                            key={`${label}-${index}`}
                        >
                            {!description && (
                                <span
                                    style={{ color: forcedLabelColor }}
                                    className={clsx(styles.label, {
                                        [styles.label_mobile]: isMobile,
                                    })}
                                >
                                    {t(label)}
                                </span>
                            )}
                            <span
                                style={{ color: forcedValueColor }}
                                className={clsx(styles.value, {
                                    [styles.value_number]: valueType === "price" && !isMobile,
                                    [styles.value_mobile]: isMobile,
                                    [styles.description_important]:
                                        !isMobile && !label && title === "information",
                                    [styles.description]: description,
                                    [styles.description_mobile]: description && isMobile,
                                    [styles.information_value_desktop]:
                                        isDesktop && (title === "information") && isNarrow
                                })}
                            >
                                <InfoListValue
                                    value={value}
                                    valueType={valueType}
                                    title={title}
                                />
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default React.memo(InfoList);
