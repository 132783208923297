interface ITradingPlatformServerTimezoneInfo {
    timezone: number;
    timezoneShift: number;
}

const ServerTimezoneInfo = () => {
    let _tradingPlatformServerTimezone = 3;

    const updateTimezoneInfo = (tradingPlatformServerTimezone: number): void => {
        _tradingPlatformServerTimezone = tradingPlatformServerTimezone;
    };

    const getTimezoneInfo = (): ITradingPlatformServerTimezoneInfo => {
        return {
            timezone: _tradingPlatformServerTimezone,
            timezoneShift: -1 * _tradingPlatformServerTimezone,
        };
    };

    return {
        updateTimezoneInfo,
        getTimezoneInfo,
    };
};

export default ServerTimezoneInfo();
