export const roundNumber = (value: number, fixed = 5, mode: "Digits" | "Point" = "Digits"): number => {
    if (!value) {
        return value;
    }

    let precision = fixed;

    if (mode === "Point") {
        precision = String(Math.round(1 / fixed)).length;
    }

    return Number(value.toFixed(precision));
};

/**
 * Price points come in 0.0001 format, we need to get the order to get toFixed param value
 */
export const trimNumberToStepValue = (value: number, step: number): string => {
    const precision = String(Math.round(1 / step)).length - 1;

    return value?.toFixed(precision);
};

/**
 * Step value in 0.0001 format
 */
export const getDecimalPrecision = (step: number): number => {
    return step && String(Math.round(1 / step)).length - 1;
};
