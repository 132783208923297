import styles from "./DepositMethodIcons.module.scss";

const DepositMethodIcons = (): JSX.Element => {
    const iconFilenames = ["visa.png", "mastercard.png", "bankwire.svg"];

    return (
        <div className={styles.container}>
            {iconFilenames.map(icon => <img key={icon} src={`/images/${icon}`} alt="" />)}
        </div>
    );
};

export default DepositMethodIcons;
