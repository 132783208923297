import { getErrorsFromResponse } from "@/services/utils";

import getConfig from "next/config";
import { CPluginErrorCodes } from "@/services/index";
import { getDomain } from "@/hooks/trade/utils";

export interface Options {
    endpoint: string;
    method: "POST" | "GET" | "DELETE" | "PUT" | "PATCH";
    contentType?: RequestContentType;
    responseContentType?: ResponseContentType;
    needAuth?: boolean;
    authToken?: string;
    body?: Record<string, any> | null;
    queryParams?: Record<string, any> | null;
    offset?: number;
    limit?: number;
    customHeaders?: Array<{ key: string; value: string }>;
}

export interface ICommonAPIErrorResponse {
    code?: CPluginErrorCodes;
    type?: string;
    message?: string;
    statusCode?: CPluginErrorCodes;
    status: number;
    isFailed: true;
    errors: string[];
}

export interface ICommonAPISuccessResponse {
    success: true;
}

export enum RequestContentType {
    JSON = "application/json",
    MULTIPART_FORM_DATA = "multipart/form-data",
}

export enum ResponseContentType {
    JSON = "application/json",
    TEXT = "text/plain",
    PDF = "application/pdf",
}

interface IRequestBody {
    body?: null | string | FormData;
}

export const getServerURL = (): string => {
    const { publicRuntimeConfig } = getConfig();
    const urlParams = new URLSearchParams(window.location.search);
    const env = publicRuntimeConfig["envMode"];
    const serverMode: "production" | "stage" | "local" = urlParams.get("server-mode") as any;
    const v5 = urlParams.get("v5") === "1";
    const host = getDomain(window.location.hostname);

    let serverURL;

    const localURL = "http://localhost:3011";
    let stageURL = "https://trader-api-stage.adesk-service.com";
    let prodURL = `https://webtrader-api.${host}`;
    //MOCKEDURLS
    if (v5) {
        stageURL = "https://webtrader-api.stage.v5.adesk-service.com";
        prodURL = `https://webtrader-api.v5.${host}`;
    }

    /**
     * Getting env variable from build level
     */
    switch (env) {
        case "production":
            serverURL = prodURL;
            break;
        case "stage":
            serverURL = stageURL;
            break;
        default:
            serverURL = prodURL;
            break;
    }

    /**
     * Used for production testing via stage build
     */
    switch (serverMode) {
        case "local":
            serverURL = localURL;
            break;

        case "production":
            serverURL = prodURL;
            break;

        case "stage":
            serverURL = stageURL;
            break;
    }
    return serverURL;
};

export const callApi = async <T = unknown>(
    options: Options
): Promise<T | ICommonAPIErrorResponse | ICommonAPISuccessResponse> => {
    const {
        endpoint: endpointPath,
        method,
        needAuth = true,
        authToken = null,
        contentType = RequestContentType.JSON,
        responseContentType = ResponseContentType.JSON,
        body = null,
        queryParams = null,
        customHeaders = null,
    } = options;
    const endpoint = `${getServerURL()}${endpointPath}`;
    const headers = new Headers();

    headers.set("Accept", responseContentType);

    if (customHeaders) {
        customHeaders.forEach(header => {
            headers.set(header.key, header.value);
        });
    }

    if (needAuth) {
        headers.set("Authorization", `Bearer ${authToken}`);
    }

    if (contentType === RequestContentType.JSON) {
        headers.set("Content-Type", "application/json");
    }

    const requestBody: IRequestBody = {};

    if (body !== null) {
        if (contentType === RequestContentType.JSON) {
            requestBody.body = JSON.stringify(body);
        } else {
            const formData = new FormData();

            for (const name in body) {
                // @ts-ignore
                formData.append(name, body[name]);
            }

            requestBody.body = formData;
        }
    }

    const fetchConfig: RequestInit = {
        headers: headers,
        method,
        ...requestBody,
    };

    const requestUrl = new URL(endpoint);

    if (queryParams !== null) {
        // @ts-ignore
        Object.keys(queryParams).forEach(key => requestUrl.searchParams.append(key, queryParams[key]));
    }

    let response;

    try {
        response = await fetch(requestUrl.toString(), fetchConfig);

        const SUCCESS_CODES = [200, 201, 202, 203, 204];

        if (method === "DELETE" && SUCCESS_CODES.includes(response.status)) {
            return {
                success: true,
            };
        }

        if (method === "PUT" && SUCCESS_CODES.includes(response.status)) {
            const responseParsed = await response.json();

            return {
                ...responseParsed,
                // @ts-ignore
                success: true,
            };
        }
    } catch (e) {
        return {
            status: 500,
            isFailed: true,
            errors: ["Request failed"],
        };
    }

    let result;

    if (responseContentType === ResponseContentType.JSON) {
        const text = await response.text();

        result = text
            ? JSON.parse(text)
            : {
                  // @ts-ignore
                  success: true,
              };
    } else if (responseContentType === ResponseContentType.PDF) {
        result = await response.blob();
    } else {
        result = await response.text();
    }

    if (![200, 201, 202, 203, 204].includes(response.status)) {
        return {
            status: response.status,
            isFailed: true,
            code: result?.code || result?.data?.code,
            type: result?.type || result?.data?.type,
            message: result?.message,
            errors: getErrorsFromResponse(result),
        };
    }

    return result;
};
