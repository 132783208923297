export const getLatestQuoteHistoryFromResponse = (response) => {
    if (!response || !Array.isArray(response)) {
        return null;
    }

    return response.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime())[0];
};

export function getDomain(hostname) {
    if (hostname === 'localhost') {
        return 'inefex.com';
    }
    hostname = hostname.split('.');
    hostname.reverse();
    return `${hostname[1]}.${hostname[0]}`;
}

