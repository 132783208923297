import { useSelector, shallowEqual } from "react-redux";

import { IStore } from "../interfaces/IStore";
import { IAccountDynamicState, IAccountStaticState } from "../interfaces/IAccount";

const getAccountStaticInfo = (state: IStore): IAccountStaticState => {
    const {
        id,
        name,
        currency,
        accountId,
        isGuest,
        margin,
        isLazyMode,
        tradingPlatformServerType,
        devmode,
        tradeAlerts,
        tradeAlertsLastRequestTime,
    } = state.account;

    return {
        id,
        name,
        currency,
        accountId,
        isGuest,
        margin,
        isLazyMode,
        tradingPlatformServerType,
        devmode,
        tradeAlerts,
        tradeAlertsLastRequestTime,
    };
};

export const getAccountInfo = getAccountStaticInfo;

export const useAccountInfo = (): IAccountStaticState => useSelector(getAccountStaticInfo, shallowEqual);

export const getFavourites = (state: IStore): string[] => state.account.favourites;

export const useFavourites = (): string[] => useSelector(getFavourites);

const getAccountDynamicInfo = (state: IStore): IAccountDynamicState => {
    const {
        balance,
        credit,
        equity,
        leverage,
        margin,
        freeMargin,
        profit,
        favourites,
        isSetDynamicAccountInfoCalculated,
    } = state.account;

    return {
        balance,
        credit,
        equity,
        leverage,
        margin,
        freeMargin,
        profit,
        favourites,
        isSetDynamicAccountInfoCalculated,
    };
};

export const useAccountDynamicInfo = (): IAccountDynamicState =>
    useSelector(getAccountDynamicInfo, shallowEqual);
