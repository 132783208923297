import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import { useTheme } from "@mui/material";

import { closeTradeOrderModal, openTradeOrderModal } from "@/redux/actions/ui";
import { closeTradeSubmit } from "@/redux/actions/orders";
import { useUI } from "@/redux/selectors/uiSelector";
import InfoList, { IContent as IInfoListContent } from "@/components/Core/InfoList/InfoList";
import { useQuoteData } from "@/hooks/trade/tradeSocket";
import { getFormattedPrice } from "@/utils/symbols";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { formatAmount } from "@/utils/format";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import Checkbox from "@/components/Core/Form/Checkbox/Checkbox";
import { useLotSize } from "@/hooks/trade/lotSize";
import NumberInput from "@/components/Core/Form/NumberInput/NumberInput";
import { ICloseOrderQueryParams } from "@/services/trade/order";
import OrderModal from "../OrderModal";
import { getTradeProfit } from "@/utils/trade";
import CommonButton from "@/components/Core/Button/CommonButton";
import { useMarketSchedule } from "@/hooks/trade/schedule";
import { ITradeOrderModalTypes } from "@/redux/interfaces/IUI";
import useAutoClearValueState from "@/hooks/common/useAutoClearValueState";
import { getSeenPrice } from "@/utils/getSeenPrice";

import styles from "./ConfirmModal.module.scss";

export const OrderCloseConfirmModal = (): JSX.Element => {
    const { palette } = useTheme();
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { tradeOrderModalParams, isPendingOrdersOpen } = useUI();
    const { currency: accountCurrency } = useAccountInfo();
    const { symbolsInfoById, symbols } = useTradeInfo();

    const [isLoading, setIsLoading] = useState(false);
    // @ts-ignore
    const order = tradeOrderModalParams?.order || {};

    const { isMarketOpen } = useMarketSchedule(order.symbol);
    const { lots, symbol, openPrice } = order;

    const { ask, bid } = useQuoteData(symbol);

    const symbolInfo = symbolsInfoById[symbol];
    const [isPartialClose, setIsPartialClose] = useState(false);
    const { lotSize, handleValueChange, handleValueDecrease, handleValueIncrease } = useLotSize({
        max: lots,
        initialValue: lots,
        min: symbolInfo?.group?.lotMin,
    });
    const [alertMessageType, setAlertMessageType] = useAutoClearValueState<"min_value" | "max_value">(
        null
    );
    const isBuy = order?.type?.toLowerCase().includes("buy");

    const calcProfit = getTradeProfit({
        openPrice: order?.openPrice,
        ask,
        bid,
        tradeType: isBuy ? "Buy" : "Sell",
        lotSize: order?.lots,
        accountCurrency,
        symbolInfo,
        symbols,
    });
    const lotMin = symbolInfo?.group?.lotMin || 0.01;
    const marketPrice = isPendingOrdersOpen ? (isBuy ? ask : bid) : isBuy ? bid : ask;

    const currentPriceValue = getFormattedPrice(marketPrice, symbolsInfoById[order.symbol]?.point);

    const orderData: IInfoListContent[] = useMemo(
        () => [
            {
                label: t("asset"),
                value: symbolInfo.ex.displayName || symbol || "---",
            },
            {
                label: t("lots"),
                value: order?.lots || "---",
            },
            {
                label: t("openPrice"),
                value: openPrice || "---",
            },
            {
                label: t("closePrice"),
                value: currentPriceValue || "---",
            },
            {
                label: t("amount"),
                value: lots || "---",
            },
        ],
        [currentPriceValue]
    );

    const isValidLotValue = lotValue => {
        if (Number(lotValue) > lots) {
            setAlertMessageType("max_value");

            return false;
        } else if (Number(lotValue) < lotMin) {
            setAlertMessageType("min_value");

            return false;
        }

        return true;
    };

    const handleConfirm = useCallback(() => {
        if (!isMarketOpen) {
            dispatch(openTradeOrderModal(ITradeOrderModalTypes.MARKET_CLOSED));

            return;
        }

        if (!isLoading) {
            setIsLoading(true);

            const additionalArgs = {
                seenAskPrice: getSeenPrice({ operation: "Buy", symbolInfo }),
                seenBidPrice: getSeenPrice({ operation: "Sell", symbolInfo }),
            };

            const request: Partial<ICloseOrderQueryParams> = {
                ticket: order?.ticket,
                volume: isPartialClose ? lotSize : null,
                ...additionalArgs,
            };

            dispatch(closeTradeSubmit(request));
        }
    }, [isLoading, isMarketOpen, isPartialClose, lotSize, order?.ticket]);

    const handleClose = () => {
        dispatch(closeTradeOrderModal());
    };

    const handleChange = value => {
        if (alertMessageType) {
            setAlertMessageType(null);
        }

        if (!isValidLotValue(value)) {
            return;
        }

        handleValueChange(Number(value));
    };

    const handleBlur = useCallback(
        value => {
            if (alertMessageType && isValidLotValue(value)) {
                setAlertMessageType(null);
            }
        },
        [alertMessageType]
    );

    const profit = calcProfit;

    const displayAlertMessage =
        alertMessageType === "min_value"
            ? `${t("min_volume")} ${symbolInfo?.group?.lotMin} ${t("lot")}!`
            : `${t("max_volume")} ${order.lots} ${t("lot")}!`;

    const Footer = useCallback(() => {
        return (
            <div className={styles.footer}>
                <CommonButton
                    className={clsx(styles.actionButton)}
                    variant="black"
                    action={handleClose}
                    title={t("no")}
                />
                <CommonButton
                    className={clsx(styles.actionButton)}
                    variant="white"
                    disabled={
                        isLoading || (!!alertMessageType && !(lotSize === lotMin || lotSize === lots))
                    }
                    action={handleConfirm}
                    title={t("yes")}
                />
            </div>
        );
    }, [alertMessageType, isLoading, isPartialClose, lotSize, order.ticket]);

    return (
        <OrderModal customFooter={<Footer />} classes={{ closeIcon: styles.closeIcon }}>
            <div className={styles.closeTradeTitleWrap}>
                <h2 className={styles.closeTradeTitle}>
                    {t("closeTrade")} #{order?.ticket}
                </h2>
            </div>
            <div className={clsx(styles.orderDetails)}>
                <InfoList
                    labelColor={palette.mode === "dark" ? "#89909c" : "#343841"}
                    valueColor={palette.mode === "dark" ? "#89909c" : "#343841"}
                    content={orderData}
                    dense
                    className={styles.infoListContainer}
                />
                <div className={styles.profitInfo}>
                    <div className={styles.profitInfoLabel}>{t("close_trade_with_profit")}</div>
                    <div
                        className={clsx(styles.profitInfoValue, {
                            [styles.profitInfoValueLoss]: profit < 0,
                            [styles.profitInfoValueProfit]: profit > 0,
                        })}>
                        {formatAmount(profit, accountCurrency)}
                    </div>
                </div>
                <div className={styles.partialClose}>
                    <form action="" noValidate>
                        <div className={styles.partialCloseCheckboxRow}>
                            <Checkbox
                                inputId="partial_close_toggle"
                                checked={isPartialClose}
                                onChange={() => {
                                    setIsPartialClose(!isPartialClose);
                                }}
                                classes={styles.partialCloseCheckbox}
                            />
                            <label
                                className={clsx(null, {
                                    [styles.partialCloseTitle]: isPartialClose,
                                })}
                                htmlFor="partial_close_toggle">
                                {t("partial_close")}
                            </label>
                        </div>
                        <div className={styles.partialCloseInputRow}>
                            <span
                                className={clsx(styles.numberLabel, {
                                    [styles.numberLabelActive]: isPartialClose,
                                })}>
                                {t("amount")}
                            </span>
                            <NumberInput
                                value={lotSize}
                                step={0.01}
                                onChange={handleChange}
                                handleValueIncrease={handleValueIncrease}
                                handleValueDecrease={handleValueDecrease}
                                disabled={!isPartialClose}
                                onBlur={handleBlur}
                                minValue={lotMin}
                                maxValue={lots}
                                fullWidth
                                allowNull
                                allowZero
                                className={styles.volumeInput}
                                handleError={setAlertMessageType}
                                allowDisplayOutOfRange={false}
                            />
                            {alertMessageType && (
                                <div className={styles.alertMessage}>{displayAlertMessage}</div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </OrderModal>
    );
};
