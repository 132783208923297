import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "next-i18next";

import { SymbolsInfoResponse } from "@/services/trade/symbols";
import { padDate } from "@/utils/format";

export type DaysOfWeek = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";

export const daysOfWeek: DaysOfWeek[] = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

const MS_IN_24_HOURS = 1000 * 60 * 60 * 24;

const is247Schedule = (scheduleSlots: [string, string][]): boolean => {
    return (
        scheduleSlots.length === 7 &&
        scheduleSlots.every(
            slot => new Date(slot[1]).getTime() - new Date(slot[0]).getTime() === MS_IN_24_HOURS
        )
    );
};

const jsNormalDayOfWeek = {
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
    0: 6,
};

function setToStartOfDay(date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
}

function setToEndOfDay(date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
}

function getStartOfWeek() {
    const now = new Date();
    const diff = now.getDate() - jsNormalDayOfWeek[now.getDay()];

    now.setDate(diff);

    setToStartOfDay(now);

    return new Date(now);
}

function getEndOfWeek() {
    const now = new Date();
    const diff = now.getDate() + (6 - jsNormalDayOfWeek[now.getDay()]);

    now.setDate(diff);

    setToEndOfDay(now);

    return new Date(now);
}

function getDateRangeStart() {
    const now = new Date();
    const diff = now.getDate() - jsNormalDayOfWeek[now.getDay()] - 2; // shift to 2 more days for timezone offsets

    now.setDate(diff);

    setToStartOfDay(now);

    return new Date(now);
}

const jsWeekDaysMap: Record<number, DaysOfWeek> = {
    0: "sun",
    1: "mon",
    2: "tue",
    3: "wed",
    4: "thu",
    5: "fri",
    6: "sat",
};

type Timeslot = [Date, Date];

export type UIMarketSchedule = Record<DaysOfWeek, Timeslot[]>;

interface IRealtimeSchedule {
    timeslots: Timeslot[];
    uiSchedule: UIMarketSchedule;
}

type OPEN247 = "OPEN247";

const ScheduleManager = () => {
    const schedules = {};

    let realtimeSchedules: Record<string, IRealtimeSchedule | OPEN247> = {};

    let schedulesArray = [];

    let symbolIdToScheduleIndexMap = {};

    let rangeStart;
    let startOfWeek;
    let endOfWeek;

    let dates = [];

    let _schedulesLastModifiedTime = null;

    const setCommonScheduleData = () => {
        realtimeSchedules = {};
        dates = [];

        rangeStart = getDateRangeStart();
        startOfWeek = getStartOfWeek();
        endOfWeek = getEndOfWeek();

        for (let i = 0; i <= 10; i++) {
            const current = new Date(rangeStart);

            current.setDate(current.getDate() + i);

            dates.push({
                year: String(current.getFullYear()),
                month: padDate(current.getMonth() + 1),
                date: padDate(current.getDate()),
                day: jsWeekDaysMap[current.getDay()],
            });
        }

        _schedulesLastModifiedTime = new Date().getTime();
    };

    const parseTradingPlatformSchedulesData = (symbolsInfoById: SymbolsInfoResponse): void => {
        Object.keys(symbolsInfoById).forEach(id => {
            const symbol = symbolsInfoById[id];
            const key = JSON.stringify(symbol.schedule); // to keep only unique schedules

            if (schedules[key]) {
                schedules[key].symbols.push(id);
            } else {
                schedules[key] = {
                    src: symbol.schedule,
                    symbols: [id],
                };
            }
        });

        schedulesArray = Object.keys(schedules).map(k => schedules[k]);

        symbolIdToScheduleIndexMap = schedulesArray.reduce((acc, schedule, index) => {
            const scheduleSymbolsInfo = schedule.symbols.reduce((symbolsAcc, symbolId) => {
                return {
                    ...symbolsAcc,
                    [symbolId]: index,
                };
            }, {});

            return {
                ...acc,
                ...scheduleSymbolsInfo,
            };
        }, {});

        setCommonScheduleData();
    };

    const generateSymbolSchedule = (symbolId: string) => {
        if (realtimeSchedules[symbolId]) {
            return realtimeSchedules[symbolId];
        }

        const symbolSchedule = schedulesArray[symbolIdToScheduleIndexMap[symbolId]]?.src || [];

        if (symbolSchedule?.length && is247Schedule(symbolSchedule)) {
            return "OPEN247";
        }

        const datesWithSchedule = symbolSchedule.map(slot => [new Date(slot[0]), new Date(slot[1])]);

        const userFriendlySchedule = datesWithSchedule.reduce((acc, slot) => {
            const [from, to] = slot;
            const fromDayOfWeek = jsWeekDaysMap[from.getDay()];
            const toDayOfWeek = jsWeekDaysMap[to.getDay()];

            if (!acc[fromDayOfWeek]) {
                acc[fromDayOfWeek] = [];
            }

            if (!acc[toDayOfWeek]) {
                acc[toDayOfWeek] = [];
            }

            if (fromDayOfWeek !== toDayOfWeek) {
                const fromDayEnd = new Date(from);
                const toDayStart = new Date(to);

                setToEndOfDay(fromDayEnd);
                setToStartOfDay(toDayStart);

                acc[fromDayOfWeek].push([from, fromDayEnd]);

                // TODO: check - sometimes we have entries like "00:00-00:00"
                if (toDayStart.getTime() !== to.getTime()) {
                    acc[toDayOfWeek].push([toDayStart, to]);
                }
            } else {
                acc[fromDayOfWeek].push([from, to]);
            }

            return acc;
        }, {});

        // combine timeslots
        Object.keys(userFriendlySchedule).forEach(day => {
            const daySchedule = userFriendlySchedule[day];
            const normalizedDaySchedule = [];

            if (daySchedule.length < 2) {
                return;
            }

            for (let i = 0; i < daySchedule.length; i++) {
                const [from, to] = daySchedule[i];
                const [nextFrom, nextTo] = daySchedule[i + 1] || [];

                if (!nextFrom || !nextTo) {
                    normalizedDaySchedule.push([from, to]);

                    break;
                }

                /**
                 * Due to timezone shifts, sometimes we end up with schedules like
                 * 00:00-12:00, 12:00-20:00 which can be grouped into single slots
                 */
                if (to.getTime() === nextFrom.getTime()) {
                    normalizedDaySchedule.push([from, nextTo]);
                    ++i;
                } else {
                    normalizedDaySchedule.push([from, to]);
                }
            }

            userFriendlySchedule[day] = normalizedDaySchedule;
        });

        realtimeSchedules[symbolId] = {
            timeslots: datesWithSchedule,
            uiSchedule: userFriendlySchedule,
        };

        return realtimeSchedules[symbolId];
    };

    const isMarketOpenForAsset = (symbolId: string): boolean => {
        const symbolSchedule = generateSymbolSchedule(symbolId);

        if (symbolSchedule === "OPEN247") {
            return true;
        }

        const { timeslots } = symbolSchedule;
        const now = new Date();

        return timeslots.some(slot => {
            const [from, to] = slot;

            return now > from && now < to;
        });
    };

    const getAssetLocalSchedule = (symbolId: string): UIMarketSchedule | OPEN247 => {
        const symbolSchedule = generateSymbolSchedule(symbolId);

        if (symbolSchedule === "OPEN247") {
            return "OPEN247";
        }

        const { uiSchedule } = symbolSchedule;

        return uiSchedule;
    };

    const getAssetTimeslots = (symbolId: string): Timeslot[] | OPEN247 => {
        const symbolSchedule = generateSymbolSchedule(symbolId);

        if (symbolSchedule === "OPEN247") {
            return "OPEN247";
        }

        const { timeslots } = symbolSchedule;

        return timeslots;
    };

    return {
        parseTradingPlatformSchedulesData,
        isMarketOpenForAsset,
        getAssetLocalSchedule,
        getAssetTimeslots,
        getLastModifiedTime: () => _schedulesLastModifiedTime,
    };
};

export const scheduleManager = ScheduleManager();

interface IMarketScheduleInfo {
    isMarketOpen: boolean;
    schedule: UIMarketSchedule | OPEN247;
    timeslots: Timeslot[] | OPEN247;
}

export const useMarketSchedule = (symbolId: string): IMarketScheduleInfo => {
    const lastModified = scheduleManager.getLastModifiedTime();

    const { isMarketOpen, schedule, timeslots } = useMemo(() => {
        if (!lastModified) {
            return { isMarketOpen, schedule: {} } as IMarketScheduleInfo;
        }

        return {
            isMarketOpen: scheduleManager.isMarketOpenForAsset(symbolId),
            schedule: scheduleManager.getAssetLocalSchedule(symbolId),
            timeslots: scheduleManager.getAssetTimeslots(symbolId),
        };
    }, [symbolId, lastModified]);

    return { isMarketOpen, schedule, timeslots };
};

const getDateTimeMarketOpen = (timeslots: Timeslot[] | OPEN247): Date | null => {
    if (timeslots === "OPEN247") {
        return null;
    }

    const nextWeekTimeslots = timeslots.map(slot => {
        return slot.map(slotDate => {
            const nextWeekDate = new Date(slotDate.getTime());

            nextWeekDate.setDate(nextWeekDate.getDate() + 7);

            return nextWeekDate;
        });
    });

    const allTimeslots = [...timeslots, ...nextWeekTimeslots];

    const now = new Date();
    const nextOpenTimeslot = allTimeslots.find(slot => slot[0] > now);

    return nextOpenTimeslot ? nextOpenTimeslot[0] : null;
};

interface IMarketWaitingTime {
    waitingTimeMessage: string;
    isMarketOpen: boolean;
}

export const useMarketWaitingTimeMessage = (symbolId: string): IMarketWaitingTime => {
    const { isMarketOpen, timeslots } = useMarketSchedule(symbolId);
    const { t } = useTranslation("common");
    const [differenceTime, setDifferenceTime] = useState(0);

    useEffect(() => {
        let timer;

        if (!isMarketOpen && symbolId) {
            const marketOpenTime = getDateTimeMarketOpen(timeslots);
            let differenceTime = +new Date(marketOpenTime) - Date.now();

            timer = setInterval(() => {
                differenceTime = differenceTime - 1000;
                setDifferenceTime(differenceTime);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isMarketOpen, symbolId, timeslots]);

    if (!isMarketOpen && differenceTime > 0) {
        const days = Math.floor(differenceTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor((differenceTime / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((differenceTime / 1000 / 60) % 60);
        const seconds = Math.floor((differenceTime / 1000) % 60);

        const waitingTimeMessage = `${t("openTradingTimes.opening")}\
                ${days !== 0 ? ` ${days} ${t("openTradingTimes.day")} ` : ""}\
                ${hours !== 0 || !!days ? ` ${hours} ${t("openTradingTimes.hour")} ` : ""}\
                ${
                    minutes !== 0 || !!days || !!hours
                        ? ` ${minutes} ${t("openTradingTimes.minute")} `
                        : ""
                }\
                ${
                    seconds !== 0 || !!days || !!hours || !!minutes
                        ? ` ${seconds} ${t("openTradingTimes.second")} `
                        : ""
                }`;

        return {
            waitingTimeMessage,
            isMarketOpen,
        };
    }

    return {
        waitingTimeMessage: "",
        isMarketOpen,
    };
};
