export const DATE_FORMAT = "YYYY-MM-DDTHH:mm";

export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const PLATFORM_INTRO_COOKIE_KEY = "wtIntroSkip";

export enum TRADE_OPERATION_TYPE {
    BUY = "Buy",
    SELL = "Sell",
}

export const GUEST_FAVOURITES_STORAGE_KEY = "trader_guest_favourites";

export const MARGIN_CALL_COOKIE_KEY = "trader_margin_call_shown";

export const SYMBOLS_IMAGES_MAP_STORAGE_KEY = "symbols_images_map";

export const TRANSACTIONS_TYPES = {
    regular_deposit: "Deposit",
    deposit_cc: "Deposit",
    adjustment: "Correction",
    regular_withdrawal: "Withdrawal",
    cancel_withdrawal_request: "Withdrawal Cancellation",
    chargeback: "Correction",
    cancel_chargeback: "Correction",
    rollover_correction: "Rollover Correction",
    psp_recall: "PSP Recall",
    refer_a_friend: "Refer a Friend",
    rebate: "Rebate",
    agreement: "Agreement",
    balance_correction: "Balance Correction",
    bo_correction: "Correction",
    cancel_withdrawal: "Cancel Withdrawal",
    dealing_correction: "Dealing Correction",
    fee: "Fee",
    fee_reimbursement: "Fee Reimbursement",
    fraud_correction: "Fraud correction",
    reset_balance: "Reset Balance",
    technical_error: "Technical Error",
    verification_bonus: "Verification Bonus",
    binaryclosure: "BinaryClosure",
    competition_winner: "Competition Winner",
    swap_corrections: "Swap Corrections",
    money_transfer: "Money Transfer",
};
