import React from "react";
import { ValueType } from "../InfoList";

import styles from "./InfoListValue.module.scss";


const PriceValue = ({ value }) => {
    const [firstValue, secondValue] = value.split(" ");
    return (
        <div className={styles.priceValue}>
            {<span>{firstValue}</span>}
            {<span>{secondValue}</span>}
        </div>
    );
};

interface IInfoListProps {
    value: string;
    valueType: ValueType;
    title: string;
}

const InfoListValue: React.FC<IInfoListProps> = ({ value, valueType }) => {
    const isPriceValue = valueType === 'price' && value.split(" ").length === 2;

    if (isPriceValue) {
        return (
            <PriceValue value={value} />
        );
    }

    return (
        <>
            {value}
        </>
    );
};

export default React.memo(InfoListValue);
