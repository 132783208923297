import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";

import { closeTradeOrderModal } from "@/redux/actions/ui";
import DepositMethodIcons from "@/components/Core/DepositMethodIcons/DepositMethodIcons";
import OrderModal from "../OrderModal";
import { useTheme } from "@mui/material";
import { useLoginInfo } from "@/hooks/common/useLoginInfo";
import parentFrameAPI from "@/services/parent";
import AudioPlayer, { AudioType } from "@/components/AudioPlayer";

import styles from "./DepositModal.module.scss";


const getIconPath = (icon: string, theme: string): string => {
    switch (icon) {
        case "insufficient-funds":
        case "market-closed":
            return `/images/${icon}_${theme}.svg`;
        default:
            return `/images/${icon}.svg`;
    }
};

interface IProps {
    icon?: string;
    title?: string;
    message?: string | ReactNode;
    actionLabel?: string;
    playAudioEffect?: boolean;
}

const DepositModal = (props: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { palette } = useTheme();
    const { isLoggedInUser } = useLoginInfo();

    const defaultTitle = t("insufficient_funds");
    const defaultMessage = t("insufficient_funds_text");

    const {
        icon = "insufficient-funds",
        title = defaultTitle,
        message = defaultMessage,
        actionLabel,
        playAudioEffect = true,
    } = props;

    const actionBtnlabel = actionLabel ? actionLabel : t("deposit");

    const handleDeposit = () => {
        if (actionLabel) {
            // TODO maybe need check actionLabel type
            dispatch(closeTradeOrderModal());
        } else {
            if (isLoggedInUser) {
                parentFrameAPI.deposit();
            } else {
                parentFrameAPI.login();
            }
        }
    };

    return (
        <OrderModal actionLabel={actionBtnlabel} action={handleDeposit}>
            {playAudioEffect && <AudioPlayer type={AudioType.ERROR} />}
            <div className={styles.topIconContainer}>
                <img
                    src={getIconPath(icon, palette.mode)}
                    alt="action disabled icon"
                    className={styles.topIcon}
                />
            </div>

            <h2 className={styles.title}>{title}</h2>
            <div className={styles.message}>{message}</div>
            {!actionLabel && (
                <div className={styles.depositIcons}>
                    <DepositMethodIcons />
                </div>
            )}
        </OrderModal>
    );
};

export default DepositModal;

export const MarketClosedModal = (): JSX.Element => {
    const { t } = useTranslation("common");
    return (
        <DepositModal
            icon="market-closed"
            title={t("openTradingTimes.marketClosed")}
            message={t("check_out_info_tab")}
            actionLabel={t("close")}
        />
    );
};

export const MarginCallModal = (): JSX.Element => {
    const { t } = useTranslation("common");
    const msg = t("marginCallMessage1");
    return (
        <DepositModal
            icon="danger"
            title={t("marginCall")}
            message={
                <div>
                    <span className={styles.errorHighlight}>{t("attention")}&nbsp;</span>
                    <span>{msg.split(":")[0]}:</span>

                    <br />
                    <span>{msg.split(":")[1]}</span>
                </div>
            }
        />
    );
};
