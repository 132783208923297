import React from "react";
import clsx from "clsx";
import { useState } from "react";
import { useTheme } from "@mui/material";

import { roundNumber, trimNumberToStepValue } from "@/utils/numbers";

import styles from "./NumberInput.module.scss";

interface IProps {
    value: number | string;
    minValue?: number;
    maxValue?: number;
    step: number;
    onChange: (number) => void;
    disabled?: boolean;
    fullWidth?: boolean;
    trim?: boolean;
    allowZero?: boolean;
    allowNull?: boolean;
    handleValueIncrease?: () => void;
    handleValueDecrease?: () => void;
    handleError?: (value: "min_value" | "max_value", withClear?: boolean) => void;
    className?: string;
    onBlur?: (value: string) => void;
    allowDisplayOutOfRange?: boolean;
}

const NumberInput = (props: IProps): JSX.Element => {
    const {
        value,
        step = 1,
        onChange,
        disabled = false,
        fullWidth = false,
        trim = true,
        allowZero = false,
        allowNull = false,
        className,
        onBlur,
        minValue,
        maxValue,
        handleError = () => { },
        allowDisplayOutOfRange = true,
    } = props;
    const { palette } = useTheme();
    const [userInput, setUserInput] = useState(String(value));
    const [isManualMode, setIsManualMode] = useState(false);
    const decimalPartMaxLength = step >= 1 ? 0 : String(step).length - 2;

    let displayValue = "";

    if (isManualMode) {
        displayValue = userInput;
    } else if (allowNull && value === null) {
        displayValue = "";
    } else if (trim) {
        displayValue = String(trimNumberToStepValue(Number(value), step));
    } else {
        displayValue = String(value);
    }

    const handleStepValueChange = (newValue: number) => {
        if (disabled || (!allowZero && Number(newValue) === 0)) {
            return;
        }

        onChange(newValue);
    };

    const allowedInput = /^\d*[.,]?\d*$/;

    const handleUserInput = (newValue: string) => {
        if (disabled) {
            return;
        }

        if (!allowedInput.test(newValue)) {
            return;
        }

        let normalizedUserInput = newValue;

        normalizedUserInput = normalizedUserInput.replace(",", ".");

        const decimalPart = normalizedUserInput.split(".")[1];

        if (decimalPart && decimalPart.length > decimalPartMaxLength) {
            return;
        }

        if (!allowDisplayOutOfRange) {
            const isValueOutOfRange = (
                minValue !== undefined && +normalizedUserInput < minValue ||
                maxValue !== undefined && +normalizedUserInput > maxValue
            );

            if (
                isValueOutOfRange &&
                !(
                    normalizedUserInput === "" ||
                    `${minValue}`.startsWith(normalizedUserInput) ||
                    `${maxValue}`.startsWith(normalizedUserInput)
                )
            ) {
                if (+normalizedUserInput < minValue) {
                    handleError("min_value");
                } else if (+normalizedUserInput > maxValue) {
                    handleError("max_value");
                }
                return;
            }
        }

        setUserInput(normalizedUserInput);
        setIsManualMode(true);

        const numberValue = Number(normalizedUserInput);

        if (minValue && numberValue < minValue) {
            handleError("min_value");
            return;
        }
        if (maxValue && numberValue > maxValue) {
            handleError("max_value");
            return;
        }
        if (!Number.isNaN(numberValue) && (numberValue !== 0 || allowZero)) {
            onChange(numberValue);
        } else if (normalizedUserInput === "" && allowNull) {
            onChange(null);
        }
    };

    const handleBlur = () => {
        setIsManualMode(false);

        onBlur && onBlur(String(value));
    };

    const handleValueIncrease = props.handleValueIncrease
        ? props.handleValueIncrease
        : () => handleStepValueChange(roundNumber(Number(value) + step, step, "Point"));

    const handleValueDecrease = props.handleValueDecrease
        ? props.handleValueDecrease
        : () => handleStepValueChange(roundNumber(Number(value) - step, step, "Point"));

    return (
        <div
            className={clsx(styles.container, {
                [styles.disabled]: disabled,
                [styles.fullWidth]: fullWidth,
            })}>
            <input
                type="text"
                className={clsx(styles.input, className)}
                value={String(displayValue)}
                step={step}
                onChange={e => handleUserInput(e.target.value.trim())}
                disabled={disabled}
                onBlur={handleBlur}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                    }
                }}
            />
            <span className={clsx(styles.actions)}>
                <span
                    className={styles[`increase${palette.mode === "light" ? "_light" : ""}`]}
                    onClick={() => {
                        if (maxValue && Number(value) + step > maxValue) {
                            handleError("max_value", true);
                        } else {
                            handleValueIncrease();
                        }
                    }}
                />
                <span
                    className={styles[`decrease${palette.mode === "light" ? "_light" : ""}`]}
                    onClick={() => {
                        if (minValue && Number(value) - step < minValue) {
                            handleError("min_value", true);
                        } else {
                            handleValueDecrease();
                        }
                    }}
                />
            </span>
        </div>
    );
};

export default React.memo(NumberInput);
