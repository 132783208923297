import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import { useTheme } from "@mui/material";

import { closeTradeOrderModal, openTradeOrderModal } from "@/redux/actions/ui";
import { deleteOrderConfirm } from "@/redux/actions/orders";
import { useUI } from "@/redux/selectors/uiSelector";
import { IOrderErrorModalParams, ITradeOrderModalTypes } from "@/redux/interfaces/IUI";
import OrderModal from "../OrderModal";
import OrderInfoView from "@/components/SystemFeedbackMobile/common/OrderInfoView";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { useMarketSchedule } from "@/hooks/trade/schedule";
import { getErrorMessageTranslation, getIconPath } from "@/utils/helpers";
import AudioPlayer, { AudioType } from "@/components/AudioPlayer";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import DepositModal from "../Deposit/DepositModal";

import { assignPriceParamsToOrder } from "@/utils/helpers";
import { useQuoteData } from "@/hooks/trade/tradeSocket";

import styles from "./OrderErrorModal.module.scss";

interface IProps {
    icon?: string;
    title?: string;
    fixedMessage?: string;
    playAudioEffect?: boolean;
    placed?: boolean;
}

// TODO: separate prop for footer action button
const OrderErrorModal = (props: IProps): JSX.Element => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const dispatch = useDispatch();
    const { icon = "warning", fixedMessage = "", playAudioEffect = true } = props;

    const { tradeOrderModalParams } = useUI();
    const error = (tradeOrderModalParams as IOrderErrorModalParams)?.error;

    const errorTranslation = getErrorMessageTranslation(error, t);

    let title = props.title || t("messages.general_error");
    let details = fixedMessage || errorTranslation || t("messages.general_error_text");

    if (props.placed) {
        title = t("your_request_submitted");
    }

    if (title === details || fixedMessage === null) {
        details = "";
    }

    return (
        <OrderModal actionLabel={t("close")} action={() => dispatch(closeTradeOrderModal())}>
            {playAudioEffect && <AudioPlayer type={AudioType.ERROR} />}
            <div className={styles.errorIconContainer}>
                <img
                    src={getIconPath({ name: icon, themeMode: palette.mode })}
                    alt="error icon"
                    className={styles.errorTopIcon}
                />
            </div>

            <h2 className={styles.errorTitle}>{title}</h2>
            <div className={styles.errorDetails}>{details}</div>
        </OrderModal>
    );
};

export default OrderErrorModal;

export const InvalidSLTPModal = (): JSX.Element => {
    const { t } = useTranslation("common");
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const symbolInfo = symbolsInfoById[activeSymbolId];
    const stopsLevel = symbolInfo?.ex?.stopsLevel || 50;

    return (
        <OrderErrorModal
            title={t("messages.130")}
            fixedMessage={t("wrongLevelText", { points: stopsLevel })}
        />
    );
};

interface IInvalidParamModalProps {
    type?: "InvalidExpirationDate";
}

export const InvalidParamsModal = ({ type }: IInvalidParamModalProps): JSX.Element => {
    const { t } = useTranslation("common");
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const symbolInfo = symbolsInfoById[activeSymbolId];
    const stopsLevel = symbolInfo?.ex?.stopsLevel || 50;

    let message = "messages.3";
    let description = t("wrongLevelText", { points: stopsLevel });

    if (type === "InvalidExpirationDate") {
        message = "modals.error.tradeDisabled.invalid.expiration.date";
        description = null;
    }

    return <OrderErrorModal icon="danger" title={t(message)} fixedMessage={description} />;
};
interface ITradeDisabledModalProps {
    type?: "ReadOnly" | "CloseOnly" | "ExpiredContract" | "LowLiquidity" | "InvalidExpirationDate";
}

export const TradeDisabledModal = ({ type }: ITradeDisabledModalProps): JSX.Element => {
    const { t } = useTranslation("common");
    const { isLazyMode } = useAccountInfo();

    let message = "";
    if (type === "CloseOnly") {
        message = "modals.error.tradeDisabled.description.close.only";
    } else if (type === "ReadOnly") {
        message = "modals.error.tradeDisabled.description";
    } else if (type === "ExpiredContract") {
        message = "modals.error.tradeDisabled.expiredContract";
    } else if (type === "LowLiquidity") {
        message = "modals.error.tradeDisabled.lowLiquidity";
    }

    if (type === "ReadOnly" && isLazyMode) {
        return <DepositModal />;
    }

    return <OrderErrorModal icon="danger" title={t("messages.133")} fixedMessage={t(message)} />;
};

export const NoConnectionModal = (): JSX.Element => {
    const { t } = useTranslation("common");

    return <OrderErrorModal icon="warning" title={t("modals.notification.secure.connection")} />;
};

export const NoQuotesModal = ({ type }: { type?: "LowLiquidity" }): JSX.Element => {
    const { t } = useTranslation("common");
    const isLiquidity = type === "LowLiquidity";
    const message = isLiquidity ? "modals.error.tradeDisabled.lowLiquidity" : "messages.136";

    return (
        <OrderErrorModal
            icon="warning"
            title={t(message)}
            fixedMessage={isLiquidity ? t(message) : ""}
        />
    );
};

export const OrderWithWrongPriceModal = (): JSX.Element => {
    const { t } = useTranslation("common");

    return <OrderErrorModal icon="warning" title={t("messages.129")} />;
};

export const OrderFailedModal = (): JSX.Element => {
    const { t } = useTranslation("common");

    return (
        <OrderErrorModal
            icon="warning"
            title={t("messages.orderFailed")}
            fixedMessage={t("messages.general_error")}
        />
    );
};

export const OpenPositionModal = (): JSX.Element => {
    const { t } = useTranslation("common");

    return (
        <OrderErrorModal
            title={t("messages.1151")}
            fixedMessage={t("modals.error.open.position.description")}
        />
    );
};

export const OrderDeleteConfirmModal = (): JSX.Element => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const { palette } = useTheme();
    const { tradeOrderModalParams } = useUI();

    // @ts-ignore
    const order = tradeOrderModalParams?.order || {};
    const { symbolsInfoById, activeSymbolId } = useTradeInfo();
    const symbolInfo = symbolsInfoById[activeSymbolId];

    const { ask, bid } = useQuoteData(activeSymbolId);
    const { isMarketOpen } = useMarketSchedule(order.symbol);
    const args = assignPriceParamsToOrder({
        ask,
        bid,
        symbolInfo,
        originalObject: {},
    });
    const executeDelete = useCallback(
        order => {
            return isMarketOpen
                ? () => dispatch(deleteOrderConfirm({ order, args }))
                : () => dispatch(openTradeOrderModal(ITradeOrderModalTypes.MARKET_CLOSED));
        },
        [isMarketOpen]
    );

    return (
        <OrderModal actionLabel={t("delete")} action={executeDelete(order)} withCancel={true}>
            <div className={styles.errorIconContainer}>
                <img src={`/images/danger.svg`} alt="error icon" className={styles.errorTopIcon} />
            </div>

            <h2 style={{ color: palette.mode === "dark" ? "#e6ecf1" : "#343841" }}>
                {t("about_delete_order")}
            </h2>
            <div className={styles.errorDetails}>
                <OrderInfoView order={order} operation="delete-confirm" />
            </div>
        </OrderModal>
    );
};
