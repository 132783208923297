import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";

import { setDeviceInfo } from "@/redux/actions/device";
import { DevicePlatform } from "@/redux/interfaces/IDevice";

interface IWindowSize {
    width: number;
    height: number;
}

export const useWindowSize = (): IWindowSize => {
    const [windowSize, setWindowSize] = useState<IWindowSize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const updateSize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        const handleWindowResize = debounce(updateSize, 500);

        window.addEventListener("resize", handleWindowResize);

        updateSize();

        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return windowSize;
};

interface IUseWindowResizeProps {
    onResize: () => void;
}

export const useWindowResize = (props: IUseWindowResizeProps): void => {
    const { onResize } = props;

    useEffect(() => {
        const handleWindowResize = debounce(onResize, 500);

        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, [onResize]);
};

export const getDevicePlatformFromScreenWidth = (width: number): DevicePlatform => {
    if (!width) {
        return null; // device not yet detected
    }

    if (width > 1024) {
        return DevicePlatform.Desktop;
    } else if (width > 600) {
        return DevicePlatform.Tablet;
    } else {
        return DevicePlatform.Mobile;
    }
};

export const useDeviceInfo = (): void => {
    const dispatch = useDispatch();
    const { width } = useWindowSize();

    // calculate device platform based on device width
    useEffect(() => {
        const deviceInfo = {
            platform: getDevicePlatformFromScreenWidth(width),
        };

        dispatch(setDeviceInfo(deviceInfo));
    }, [dispatch, width]);
};
