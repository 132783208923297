import i18next from "i18next";

interface IGenericErrorResponse {
    message: string;
}

interface IFieldsErrorResponse extends IGenericErrorResponse {
    data: unknown;
}

interface IConstraintsErrorResponse extends IFieldsErrorResponse {
    data: {
        errors: Array<{
            constraints: unknown;
        }>;
    };
}

type ErrorResponseTypes = IGenericErrorResponse | IFieldsErrorResponse | IConstraintsErrorResponse;

export const getFieldsErrors = (response: IFieldsErrorResponse): string[] => {
    if (typeof response?.data !== "object") {
        return [];
    }

    const fieldErrors = response?.data || {};

    return Object.keys(fieldErrors).map(key => `${response?.message}: ${key} ${fieldErrors[key]}`);
};

export const getConstraintsErrors = (response: IConstraintsErrorResponse): string[] => {
    const responseErrors = response?.data?.errors || [];
    return responseErrors
        .reduce((allErrors, { constraints = {} }) => {
            const fieldErrors = Object.keys(constraints).reduce(
                (fieldErrors, key) => [
                    ...fieldErrors,
                    `${response?.message}: (${key}) ${constraints[key]}`,
                ],
                []
            );
            return [...allErrors, ...fieldErrors];
        }, [])
        .filter(Boolean);
};

export const getErrorsFromResponse = (response: ErrorResponseTypes): string[] => {
    const t = i18next.getFixedT(null, "common");

    // @ts-ignore
    if (response?.data?.errors?.[0]?.constraints) {
        return getConstraintsErrors(response as IConstraintsErrorResponse);
    }

    const fieldErrors = getFieldsErrors(response as IFieldsErrorResponse);

    if (fieldErrors.length) {
        return fieldErrors;
    }
    const errorMessage = response?.message; //|| t("api.common.unknownError");
    return [errorMessage];
};
