import { useEffect, useRef, useState } from "react";

type UseAutoClearValueReturnType<T> = [T, (value: T, withClear?: boolean) => void];

/**
 * This is a custom hook provides common useState hook functionality + reset value by timeout.
 *
 * @param {T} initialValue - initial state value.
 * @param {number} delay - time after which value to reset to null or replace on specified.
 * @returns {array} - [value, setValue].
 */
const useAutoClearValueState = <T = string>(
    initialValue: T,
    delay = 3000,
    rollbackValue: T = null
): UseAutoClearValueReturnType<T> => {
    const [value, setValue] = useState(initialValue);
    const [isAutoClearActive, setAutoClearActive] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout>(null);

    const setNewValue = (newValue: T, withClear?: boolean) => {
        if (withClear) {
            setAutoClearActive(true);
        }
        setValue(newValue);
    };

    useEffect(() => {
        if (isAutoClearActive) {
            timeoutRef.current = setTimeout(() => {
                setValue(rollbackValue ?? null);
            }, delay);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (isAutoClearActive) {
                setAutoClearActive(false);
            }
        };
    }, [value]);

    return [value, setNewValue];
};

export default useAutoClearValueState;
