import React from "react";
import clsx from "clsx";

import styles from "./CommonButton.module.scss";


interface ICommonButtonProps {
    action: () => void;
    disabled?: boolean;
    title: string;
    variant?: string;
    withBorder?: boolean;
    className?: string;
}

const CommonButton: React.FC<ICommonButtonProps> = ({
    action,
    disabled = false,
    title,
    variant = "white",
    withBorder = false,
    className = ""
}) => (
    <div
        className={clsx(styles.action_btn, styles[variant], {
            [styles.disabled]: disabled,
            [styles.withBorder]: withBorder,
        }, className)}
        onClick={!disabled ? action : () => {}}
    >
        {title}
    </div>
);

export default React.memo(CommonButton);

