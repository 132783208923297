import { MutableRefObject } from "react";
import debounce from "lodash/debounce";
import moment from "moment";
import isEqual from "lodash/isEqual";
import {
    getSymbolQuoteHistory,
    getSymbolQuoteHistory_Old,
    HistoryTimeframe,
    ISymbolInfo,
    ISymbolQuoteHistoryResponse,
} from "@/services/trade/symbols";
import { getSymbolQuoteValue, IQuoteInfo, quoteSocketManager } from "@/hooks/trade/tradeSocket";
import {
    initPriceChartType,
    initTradeChartTimeframe,
    TradeChartPriceType,
    TradeChartTimeframe,
    TradeChartType,
} from "@/hooks/chart/index";
import { IChartInstanceObject } from "@/hooks/chart/interfaces";

export const getSymbolPrecision = (point: number): number => String(Math.round(1 / point)).length - 1;

export function loadScript(url: string, callback: () => void, id = ""): void {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.onload = () => callback();
    script.src = url;

    if (id) {
        script.id = id;
    }

    document.getElementsByTagName("head")[0].appendChild(script);
}

/**
 * Chart instance initializer
 */
export function runChartInstance(
    activeSymbolId,
    dispatch,
    chartInstanceRef: MutableRefObject<IChartInstanceObject | null>,
    isDesktop: boolean,
    symbolInfo: ISymbolInfo,
    theme: string,
    showLoader: () => void,
    hideLoader: () => void,
    onComplete: () => void,
    chartType: TradeChartType,
    isMarketOpen: boolean
): void {
    // @ts-ignore
    if (!window || !window.ProChart_InitLayout) {
        return;
    }

    // @ts-ignore
    const getLayoutIds = (type = 3) => {
        const ids = [];

        if (type === TradeChartType.EditOrder) {
            ids[0] = "#trading_chart_edit_trade";
            ids[1] = "layout_edit_trade";

            return ids;
        }

        if (type === TradeChartType.PendingOrder) {
            ids[0] = "#trading_chart_pending_orders_mobile";
            ids[1] = "layout_pending_orders_mobile";

            return ids;
        }

        if (!isDesktop) {
            ids[0] = "#trading_chart_mobile";
            ids[1] = "layout_mobile";

            return ids;
        } else {
            ids[0] = "#trading_chart";
            ids[1] = "layout1";

            return ids;
        }
    };

    // @ts-ignore
    const chartObject = window?.ProChart_InitLayout(
        getLayoutIds(chartType)[0],
        getLayoutIds(chartType)[1],
        "default"
    );

    chartObject.api.props.chart.gui.zoom.interval_font_color = "rgba(0,0,0,0)";

    chartObject.guiinit.rootpath = window?.location.origin;
    chartObject.guiinit.customJsonCssFile = `${chartObject.guiinit.rootpath}/ChartUI/layouts/${
        getLayoutIds(chartType)[1]
    }/templates/default/chart${theme === "dark" ? "-dark" : ""}.css.json.js`;

    chartObject.gui.init.timeframe = [
        { n: "1 Minute", id: "1m", f: 1, t1: "1", t2: "m" },
        { n: "5 Minutes", id: "5m", f: 1, t1: "5", t2: "m" },
        { n: "15 Minutes", id: "15m", f: 1, t1: "15", t2: "m" },
        { n: "30 Minutes", id: "30m", f: 1, t1: "30", t2: "m" },
        { n: "1 Hour", id: "1h", f: 1, t1: "1", t2: "h" },
        { n: "4 Hours", id: "4h", f: 1, t1: "4", t2: "h" },
        { n: "1 Day", id: "1d", f: 1, t1: "1", t2: "d" },
        { n: "1 Week", id: "1w", f: 1, t1: "1", t2: "w" },
        { n: "1 Month", id: "1MON", f: 1, t1: "1", t2: "M" },
    ];

    chartObject.apiinit.patterncombine = true;
    chartObject.events.layout.readyComplete = () => {
        //objChartMain1.api.layout.set('width||height', 950 + "||" + 568); // for future use
    };
    chartObject.events.layout.initComplete = () => {
        chartObject.api.debug.level = 0;
        chartObject.apiinit.chart.ShowBarData = "true";
        chartObject.apiinit.chart.PriceChartType =
            chartType === TradeChartType.EditOrder ? TradeChartPriceType.Area : initPriceChartType;
        chartObject.chart.EHMaxZoomOut = 1000000;
        chartObject.chart.StudiesToLoad = []; // Make sure no default studies loaded
        chartObject.chart.Symbol = symbolInfo?.id; // Set first asset to show
        chartObject.chart.SymbolName = symbolInfo?.ex?.displayName; // Set first asset name
        chartObject.chart.TimeScale =
            chartType === TradeChartType.EditOrder ? TradeChartTimeframe.Min15 : initTradeChartTimeframe; // Set first time-scale
        chartObject.chart.vAxis.smartRound = false;
        chartObject.chart.Precision = getSymbolPrecision(symbolInfo?.point); // Set first number of decimal digits
        chartObject.chart.WaterMark = false;

        if (chartType === TradeChartType.EditOrder) {
            chartObject.chart.MarkersCornerRadius = 8;
            // TODO: restore this when the chart exception is fixed
            // chartObject.chart.AnimLightDot = true;
        }

        let lastQuoteHistoryRequest;

        // Set the data callback. Whenever "getrecenthistory" is called the chart changed symbol/asset or time-scale
        chartObject.chart.JSPush = {
            enableHistory: true,
            enableRT: true,
            enableSymbols: true,
            enableTemplates: false,
            enableEarlyHistory: true,
            earlyHistorySize: 100,
            funcFetchData: async function (
                objChartContext,
                objRequestContext,
                strRequest,
                objParameters
            ) {
                if (strRequest.toLowerCase() === "getrecenthistory") {
                    if (!lastQuoteHistoryRequest) {
                        lastQuoteHistoryRequest = {
                            ...objParameters,
                            timestamp: Date.now(),
                        };
                    } else {
                        const timestampDiff = Date.now() - lastQuoteHistoryRequest.timestamp;
                        delete lastQuoteHistoryRequest.timestamp;
                        const isEqualRequestsParams = isEqual(lastQuoteHistoryRequest, objParameters);
                        if (isEqualRequestsParams && timestampDiff < 500) {
                            lastQuoteHistoryRequest = undefined;
                            return;
                        }
                    }
                }

                await chartEventLoopPushHandler(
                    activeSymbolId,
                    dispatch,
                    chartObject,
                    objChartContext,
                    objRequestContext,
                    strRequest,
                    objParameters,
                    showLoader,
                    hideLoader,
                    chartType,
                    isMarketOpen
                );
            },
        };
    };

    chartObject.events.chart.createComplete = () => {
        chartInstanceRef.current = chartObject;

        // @ts-ignore
        window.debugChart = chartObject;

        onComplete();
    };

    chartObject.gui.loadLayout(); // Init all inner object
}

/**
 * Chart event loop handler
 */
const realtimeUpdateSymbolId = {
    [TradeChartType.Desktop]: null,
    [TradeChartType.Mobile]: null,
    [TradeChartType.EditOrder]: null,
    [TradeChartType.PendingOrder]: null,
};
const symbolRTUpdateHandler = {
    [TradeChartType.Desktop]: null,
    [TradeChartType.Mobile]: null,
    [TradeChartType.EditOrder]: null,
    [TradeChartType.PendingOrder]: null,
};

const subscribeChartToSocketUpdates = (
    dispatch,
    symbolId,
    chartReference,
    objChartContext,
    objRequestContext,
    strRequest,
    chartType: TradeChartType
) => {
    quoteSocketManager.unsubscribe(realtimeUpdateSymbolId[chartType], symbolRTUpdateHandler[chartType]);

    realtimeUpdateSymbolId[chartType] = symbolId;

    symbolRTUpdateHandler[chartType] = (quote: IQuoteInfo) => {
        const { ask, bid } = quote;
        const now = new Date();
        const data = [
            {
                id: realtimeUpdateSymbolId[chartType],
                lastTimeStamp: now.getTime(),
                data: [
                    {
                        ask: ask,
                        bid: bid,
                        other: 0,
                        // must be in GMT in the following format: yyyy-mm-dd HH:MM:ss
                        date: now.toISOString().replace("T", " ").substr(0, 19),
                    },
                ],
            },
        ];

        chartReference?.chart?.Push(objChartContext, objRequestContext, strRequest, data);
    };

    quoteSocketManager.subscribe(dispatch, symbolId, symbolRTUpdateHandler[chartType]);
};

const unsubscribeChartFromSocketUpdates = (chartType: TradeChartType) => {
    if (realtimeUpdateSymbolId[chartType] && symbolRTUpdateHandler[chartType]) {
        quoteSocketManager.unsubscribe(
            realtimeUpdateSymbolId[chartType],
            symbolRTUpdateHandler[chartType]
        );
    }

    realtimeUpdateSymbolId[chartType] = null;
    symbolRTUpdateHandler[chartType] = null;
};

interface IHistoryEventObjParams {
    iTop: number;
    strExtraData: string;
    strFieldsMode: string;
    strPriceType: string;
    strSymbol: string;
    strTimeFrame: string;
    strUserID: string;
    iLastDate: string;
}

interface IRTEventObjParams {
    strExtraData: string;
    strLastTimeStamps: string;
    strSeparator: string;
    strSymbols: string;
    strTimeFrame: string;
    strUserID: string;
}

type ChartEventObjParams = IHistoryEventObjParams | IRTEventObjParams;

const processHighLowData = debounce((symbolId, response) => {
    const todayDateStringPart = new Date().toISOString().substring(0, 10);

    try {
        const currentDayInfo = response.quoteHistory.data.filter(
            ({ time }) => time.substring(0, 10) === todayDateStringPart
        );

        if (!currentDayInfo.length) {
            return;
        }

        const { high, low } = currentDayInfo.reduce(
            (acc, entry) => {
                return {
                    high: acc.high === null ? entry.high : entry.high > acc.high ? entry.high : acc.high,
                    low: acc.low === null ? entry.low : entry.low < acc.low ? entry.low : acc.low,
                };
            },
            { high: null, low: null }
        );

        quoteSocketManager.updateHigLowFromChartHistory(symbolId, high, low);
    } catch (e) {
        // ignore errors
    }
}, 300);

let _lastDate;
let allDataIsLoaded = false;
export async function chartEventLoopPushHandler(
    activeSymbolId,
    dispatch,
    chartReference: IChartInstanceObject,
    objChartContext: unknown,
    objRequestContext: unknown,
    strRequest: string,
    objParameters: ChartEventObjParams,
    showLoader: () => void,
    hideLoader: () => void,
    chartType: TradeChartType,
    isMarketOpen
) {
    if (!chartReference || !chartReference.chart || !strRequest) {
        return;
    }

    let data;
    //let uploaded_data = [];
    // we need to do this because chart instance expect data set on every event that will be called
    // if ignore that zoom will freeze
    const setExistData = () => {
        // @ts-ignore
        const symbol = objParameters?.strSymbols || objParameters?.strSymbol;

        if (data) {
            chartReference?.chart?.Push(objChartContext, objRequestContext, strRequest, data);
        } else {
            chartReference?.chart?.Push(objChartContext, objRequestContext, strRequest, {
                // @ts-ignore
                id: symbol,
                lastTimeStamp: new Date().getTime(),
                data: [],
            });
        }
        hideLoader();
    };
    //@ts-ignore
    chartReference.events.chart.onChangeTimeframe = function (parentid, TimeFrameID) {
        _lastDate = null;
        showLoader();
        setExistData();
        return;
    };

    switch (strRequest.toLowerCase()) {
        case "getearlyhistory": {
            const { strTimeFrame, iLastDate, iTop } = objParameters as IHistoryEventObjParams;
            // @ts-ignore
            const symbol = objParameters?.strSymbols || objParameters?.strSymbol;
            // need to load data only for 15M timeframe
            if (!strTimeFrame || strTimeFrame !== "15m") {
                setExistData();
                return;
            }

            // no need to load data when we reached to the end of the period
            if (allDataIsLoaded) {
                setExistData();
                hideLoader();
                return;
            }
            showLoader();
            unsubscribeChartFromSocketUpdates(chartType);

            const response = (await getSymbolQuoteHistory_Old({
                symbol: symbol,
                timeframe: HistoryTimeframe.M15,
                date: _lastDate,
            })) as ISymbolQuoteHistoryResponse;

            const dataToParse = response?.quoteHistory?.data;

            if (response?.isFailed || !dataToParse || (dataToParse && !dataToParse.length)) {
                if (response?.isFailed) allDataIsLoaded = true;
                hideLoader();
                setExistData();
                return;
            }
            const firstElement = dataToParse[0];
            const firstElementTime = firstElement?.time;
            const fd = moment(firstElementTime).toISOString();

            _lastDate = fd;

            if (activeSymbolId !== response?.quoteHistory?.symbol) {
                setExistData();
                return;
            }

            const transformedHistory = (dataToParse || [])
                .filter(({ open, close }) => open > 0 && close > 0)
                .map(historyEntry => {
                    return {
                        open: historyEntry.open,
                        close: historyEntry.close,
                        low: historyEntry.low,
                        high: historyEntry.high,
                        other: 0,
                        // must be in GMT in the following format: yyyy-mm-dd HH:MM:ss
                        date: historyEntry.time.substring(0, 19).replace("T", " "),
                    };
                });

            const now = new Date();

            data = {
                // @ts-ignore
                id: symbol,
                lastTimeStamp: now.getTime(),
                data: strTimeFrame === "15m" ? transformedHistory : transformedHistory.slice(-1 * iTop),
            };
            chartReference?.chart?.Push(objChartContext, objRequestContext, strRequest, data);

            hideLoader();

            break;
        }

        case "getrecenthistory": {
            showLoader();

            unsubscribeChartFromSocketUpdates(chartType);

            const { strSymbol, strTimeFrame, iTop } = objParameters as IHistoryEventObjParams;
            const timeframeMap = {
                "1m": HistoryTimeframe.M1,
                "5m": HistoryTimeframe.M5,
                "15m": HistoryTimeframe.M15,
                "30m": HistoryTimeframe.M30,
                "1h": HistoryTimeframe.H1,
                "4h": HistoryTimeframe.H4,
                "1d": HistoryTimeframe.D1,
                "1w": HistoryTimeframe.W1,
                "1MON": HistoryTimeframe.MN1,
            };

            // NOTE: client-side high/low correction depends on default timeframe being less than 1 hour
            const requestedTimeframe = timeframeMap[strTimeFrame] || HistoryTimeframe.M5;
            const is5MTimeframe = requestedTimeframe === HistoryTimeframe.M5;

            const response = (await getSymbolQuoteHistory({
                symbol: strSymbol,
                timeframe: requestedTimeframe,
            })) as ISymbolQuoteHistoryResponse;
            if (!_lastDate && requestedTimeframe === HistoryTimeframe.M15) {
                const data = response?.quoteHistory?.data;

                const time = data[0].time;
                _lastDate = moment(time).toISOString();
                allDataIsLoaded = false;
            }
            if (activeSymbolId !== response?.quoteHistory?.symbol) {
                return;
            }

            /**
             * required format
             * {
                "open": 0.8151,
                "close": 0.8157,
                "low": 0.8143,
                "high": 0.8172,
                "ask": 0.816,
                "bid": 0.8157,
                "other": 0,
                "date": "2016-06-24 15:30:00"
               }
             *
             */

            const transformedHistory = (response?.quoteHistory?.data || [])
                .filter(({ open, close }) => open > 0 && close > 0)
                .map(historyEntry => {
                    return {
                        open: historyEntry.open,
                        close: historyEntry.close,
                        low: historyEntry.low,
                        high: historyEntry.high,
                        other: 0,
                        // must be in GMT in the following format: yyyy-mm-dd HH:MM:ss
                        date: historyEntry.time.substring(0, 19).replace("T", " "),
                    };
                });

            const now = new Date();

            data = {
                // @ts-ignore
                id: strSymbol,
                lastTimeStamp: now.getTime(),
                data: strTimeFrame === "15m" ? transformedHistory : transformedHistory.slice(-1 * iTop),
            };
            chartReference?.chart?.Push(objChartContext, objRequestContext, strRequest, data);
            hideLoader();

            if (is5MTimeframe) {
                processHighLowData(strSymbol, response);
            }

            break;
        }

        case "getmultirt": {
            const { strSymbols } = objParameters as IRTEventObjParams;
            const { ask, bid, lastBid, lastAsk } = getSymbolQuoteValue(strSymbols);
            const now = new Date();

            if (!lastBid || !lastAsk) {
                return;
            }
            data = [
                {
                    id: strSymbols,
                    lastTimeStamp: now.getTime(),
                    data: [
                        {
                            ask: ask,
                            bid: bid,
                            other: 0,
                            // must be in GMT in the following format: yyyy-mm-dd HH:MM:ss
                            date: now.toISOString().replace("T", " ").substr(0, 19),
                        },
                    ],
                },
            ];

            if (isMarketOpen) {
                chartReference?.chart?.Push(objChartContext, objRequestContext, strRequest, data);
            }

            if (!symbolRTUpdateHandler[chartType]) {
                subscribeChartToSocketUpdates(
                    dispatch,
                    strSymbols,
                    chartReference,
                    objChartContext,
                    objRequestContext,
                    strRequest,
                    chartType
                );
            }

            break;
        }
    }
}

type ChartLineType = "StopLoss" | "TakeProfit";

export const drawHLine = (
    price: number,
    lineType: ChartLineType,
    previousLineId: string,
    chartInstance: IChartInstanceObject,
    colorMode: string,
    chartType: TradeChartType
): string => {
    deleteHLine(previousLineId, chartInstance);

    const label = lineType === "StopLoss" ? "Stop Loss" : "Take Profit";

    const lineColor = lineType === "StopLoss" ? "#f25555" : "#16bb6f";

    const boxColor = lineType === "StopLoss" ? "#f25555" : "#16bb6f";

    const lineId =
        chartInstance?.chart?.Shapes &&
        chartInstance.chart.Shapes.AddHLine(
            "price", // always price
            price, // price value
            label, // label
            lineColor, // line color
            1, // line width
            chartType === TradeChartType.EditOrder ? [4, 1] : [10, 2], // dash style
            colorMode === "dark" ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)", // label color
            "13px Inter", // label font
            "right", //
            0, // horizontal padding
            [0, 0, 0, 0], // margins
            true,
            "rgba(0,0,0,0)"
        );

    chartInstance?.chart?.Shapes &&
        chartInstance.chart.Shapes.UpdateShape(
            "price",
            lineId,
            [
                "outOfRangeAxisMarker",
                true,
                "outOfRangeBoxColor",
                boxColor,
                "outOfRangeTextColor",
                "#fff",
                "outOfRangeLabel",
                label,
                "boxColor",
                boxColor,
                "boxTextColor",
                "#fff",
            ],
            true
        );

    return lineId;
};

export const deleteHLine = (lineId: string, chartInstance: IChartInstanceObject): void => {
    if (lineId && chartInstance.chart?.Shapes?.DeleteHLine) {
        chartInstance.chart.Shapes.DeleteHLine("price", lineId);
    }
};
